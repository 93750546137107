import React, {useState} from "react"
import styled from "styled-components"
import TileSection from "../../Components/TileLine/TileLine";
import RemainCounter from "../../Components/RemainCounter/RemainCounter";
import RemainCounterDaysOnly from "../../Components/RemainCounter/RemainCounterDaysOnly";
import RemainCounter2 from "../../Components/RemainCounter/RemainCounter2";

const MainIndex = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column; 
    z-index: 0;
`

export default function MainPage(props: any){

    const CounterSection = () =>{
        const [expand, setExpand] = useState<boolean>(false);        
        if(expand){
            return(
                <div onClick={() => {setExpand(false)}} className="counter">
                    <RemainCounter2 />
                </div>
            )
        }
        return(
            <div onClick={() => {setExpand(true)}} className="counter"> 
                <RemainCounter2 />
            </div>
        )
    }

    return(
        <MainIndex>
            {/* <PanoramaSection><img src={tmn} width={"100%"} ></img></PanoramaSection> */}
            <TileSection/>
            <CounterSection/>
        </MainIndex>
    )
}