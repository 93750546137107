import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper/Paper'

import { Form } from 'react-bootstrap';

import NewsCard from '../../Components/NewsCard/NewsCard';

const NewsSection = styled.section`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #efefefae;

`
const NewsTile = styled(Paper)`
    max-width: 1120px;
    width: 80%;
    height: fit-content;
    min-height: 150px;
    
    margin: 10px;
    text-align: left;

    display: flex;
    flex-direction: column;

    h3{
        margin: 10px;
        text-indent: 10px;
    }

    p{
        margin: 10px;
        text-indent: 20px;
    }
`

export default function NewsCurrent() {
    const navigate = useNavigate();
    const id  = useParams();
    const [pState, setPState] = useState<"editing"|"success"|"error"|"deleted">("editing");

    const [postBody, setPostBody] = useState(""); 
    const [header, setHeader] = useState("");
    const [anotation, setAnotation] = useState("");

    const [valid, setValid] = useState<boolean>(true);

    useEffect(() => {
        console.log(id);
        const getPostBody = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_news_by_id`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : (localStorage.getItem("token") as string)
                },
                body: JSON.stringify({
                    news_id: id.id
                })
            })
            res
                .json()
                .then(res => {
                    if(res.res === 0){
                        setAnotation(res.data.anotation);
                        setPostBody(res.data.content);
                        setHeader(res.data.header);
                        document.getElementById("pre");
                    }else{
                        return;
                    }
                })
        } 
        getPostBody();
    }, [])
  return (
    <NewsSection>
        <NewsCard header={header} anotation={anotation} content={postBody} id={id.id} forward={false}/>
    </NewsSection>
  )
}
