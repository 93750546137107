import React from "react"
import NavBar from "./Components/NavBar/NavBar"
import Footer from "./Components/Footer/Footer"
import { Outlet } from "react-router-dom"
import { headerData } from "./Components/NavBar/headerData"

export default function Layout(){
    
    
    return(
        <>
            <NavBar headerData={headerData}/>
                <div className="App">
                    <Outlet/>
                </div>    
            <Footer/>
        </>
    )


}