import React, {useState} from 'react'
import {useNavigate} from "react-router-dom";


import {Button, FormControl, FormLabel, TextField} from "@mui/material"

export default function AdminAddExtraPage() {

    const [name, setName] = useState("");
    const redirect = useNavigate();

    const addHander = async () => {
        redirect(-1);
        const url = `${process.env.REACT_APP_API_URL}index/insert_addition`;
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body : JSON.stringify({
                info: name
            })
        })
        
        res
            .json()
            .then(data => console.log(data))
        
    }

  return (
    <div>
        <FormControl>
            <FormLabel>Название услуги</FormLabel>
            <TextField onChange={(e) => {setName(e.target.value)}}></TextField>
            <Button onClick={addHander} >Добавить</Button>
        </FormControl>
        
    </div>
  )
}
