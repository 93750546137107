import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Routes, Route,  useNavigate, useLocation} from "react-router-dom"
import {useEffect} from "react"

import Layout from './Layout';
import MainPage from './Pages/MainPage/MainPage';
import AuthPage from './Pages/AuthPage/AuthPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import ContactPage from './Pages/ContactPage/ContactPage';
import ExtraPage from './Pages/ExtraPage/ExtraPage';

import AdminApp from './AdminApp/AdminApp';
import HelpPage from './Pages/HelpPage/HelpPage';
import SelectionPage from './Pages/SelectionPage/SelectionPage';
import ManagerPage from './Pages/ManagerPage/ManagerPage';
import BuilderPage from './Pages/BuilderPage/BuilderPage';
import PhotosPage from './Pages/PhotosPage/PhotosPage';
import NewsPage from './Pages/NewsPage/NewsPage';
import NewsCurrent from './Pages/NewsCurrent/NewsCurrent';


function App() {

  const navigate = useNavigate();
  const pathname = useLocation();
  // pathname
  //gting local storage items 
  var token = localStorage.getItem("token")
  var role = localStorage.getItem("user_role")
  
  //check are user is admin
  useEffect(() => {
    
    const checkToken = async () => {
      const url = `${process.env.REACT_APP_API_URL}index/get_contract_data`;
      const res = await fetch(url, {
          method: "POST",
          headers: {
            "Authorization" : localStorage.getItem("token") as string
          },
          body : JSON.stringify({
            user_id: (localStorage.getItem("id") as string)
          })
      })
      res
        .json()
        .then((data) => {
          console.log(data);
          if(data.auth_code === 400){
            console.log("token", localStorage.getItem("token"));
            localStorage.setItem("token", "");
            navigate("/auth");
          }
          if(data.res == 0){
            localStorage.setItem("date_start", data.data.date_start);
            localStorage.setItem("date_end", data.data.date_end);
            
            if(data.data.money_total !== null && data.data.money_total !== 'null'){
              localStorage.setItem("money_total", data.data.money_total);
            }
            if(data.data.money_payed !== null && data.data.money_total !== 'null'){
              localStorage.setItem("money_payed", data.data.money_payed);
            }
          }
          if(data.res === -1){
            return;
          }
            
        })
    }

    const checkAuth = () => {
      if(token === null || token === ""){       
        navigate("/auth")      
      }
    }


    if(pathname.pathname !== "/auth"){
      checkToken();
      checkAuth();
    }

    window.scrollTo(0,0);
    // eslint-disable-next-line
   }, [pathname]);
   

  if(role === "admin"){ //if role admin render admin application 
    return(
      <AdminApp/>
    )
  }

  return ( //else interpritate it like a user side app 
    <>
        <Routes>
          <Route path="/auth">
            <Route index element={<AuthPage/>}/>
          </Route>
          <Route path='/' element={<Layout/>}>
            <Route index element={<MainPage/>}/>
            <Route path='/profile' element={<ProfilePage/>}/>
            <Route path='/contact' element={<ContactPage/>}/> 
            <Route path="/extra" element={<ExtraPage/>}/>{/*это дополнительные услуги*/}
            <Route path='/help' element={<HelpPage/>}/>
            <Route path='/selection' element={<SelectionPage/>}/>
            <Route path='/manager' element={<ManagerPage/>}/>
            <Route path='/builder' element={<BuilderPage/>}/>
            <Route path='/photos' element={<PhotosPage/>}/>
            <Route path='/news' element={<NewsPage/>}/>
            <Route path='/news/:id' element={<NewsCurrent/>}/>
            <Route path='*' element={<NotFoundPage/>}/>
          </Route>   
        </Routes>
    </>
  );

}

export default App;
