import React from 'react'

import styled from 'styled-components'
import UserForm from '../../Components/AdminUserCreateForm/UserForm';

const UserIndex = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
`

export default function AdminCreateUser() {
  
  
    return (
    <UserIndex>
        <UserForm/>
    </UserIndex>
  )
}
