import React from 'react'
import MainTile from '../../../Components/MainTile/Tile'
import TileOutside from '../../../Components/MainTile/TileOutside'
import styled from 'styled-components'
import { AdminButtons } from './AdminButtons'

const TileLine = styled.section`
    width: fit-content;
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    background-color: var(--bs-gray-100);
    padding-top: 20px;
`

const TileWrap = styled.div`
    display: flex;
    width: 100%;
    
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap; 
`
type tileProps = {
    image: any
    title: string,
    desc: string,
    redirect: string
}
export default function AdminTileLine(){
    return(
        <TileLine>
                <TileWrap>
                    {AdminButtons.map((item: tileProps, index: number) => {
                        if((item.redirect).includes("http")){
                            return(
                                <TileOutside title={item.title} desc={item.desc} image={item.image} key={index} redirect={item.redirect} descWidth={"200px"}></TileOutside>
                            )
                        }else{
                           return(
                                <MainTile title={item.title} desc={item.desc} image={item.image} key={index} redirect={item.redirect} descWidth={"200px"}></MainTile>
                            ) 
                        }
                    })}
                </TileWrap>
        </TileLine>
    )
}

