import React from 'react'
import ExtraTable from '../../Components/ExtraList/ExtraTable'
import styled from 'styled-components'


const ExtraIndex = styled.div`
    width: 100%;
    height: fit-content;
`

export default function ExtraPage() {
  return (
    <ExtraIndex>
        <h2 style={{margin: "10px"}}>Выбор дополнительных услуг</h2>
        <ExtraTable></ExtraTable>
    </ExtraIndex>
  )
}
