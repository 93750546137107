import React from 'react'
import "./helperstyle.css"

export default function ContactTemplate(props : any) {

  const phone = `tel: ${props.phone}`;
  const mailto = `mailto:${props.email}`;

  if(props){
    return (
      <section className='helper-section '>
          <div className='container '>
            <div className='row'>
                <div className='col-12 col-md-6 image-section'>
                    <img src={`${process.env.REACT_APP_METRY_CAB_IMAGE_BASE_URL}${props.image}`} alt="не удалось загрузить изображение  " width={"300px"} className='employe'/>
                </div>
                <div className='col-12 col-md-6 contact-section'>
                    <h2 className='sname'>{props.user_name}</h2>
                     
                    <h5 className='mail'>Почта: <a className='contacts' href={mailto} type='mail'>{props.email}</a></h5>
                     
                    <h5 className='phone' >Телефон: <a className='contacts' href={phone} type='phone'>{props.phone}</a></h5>  
                  
                </div>  
              </div>
          </div>
      </section>
    )
  }

  // return (
  //   <section className='helper-section '>
  //       <div className='container '>
  //         <div className='row'>
  //             <div className='col-12 col-md-6 image-section'>
  //                 <img src={employe} alt="sorry" width={"300px"} className='employe'/>
  //             </div>
  //             <div className='col-12 col-md-6 contact-section'>
  //                 <h3 className='sname'>Нестеренко Александр Владимирович</h3>
  //                 <p className='stext'>Руководитель коммерческого блока</p>
  //                 <a href="" type='mail'>an@metrygroup.ru</a>
  //                 <br />
  //                 <a href="tel:8(3452)562425" type='phone'>{"8(3452)56 24 25"}</a>
  //             </div>  
  //           </div>
  //       </div>
  //   </section>
  // )
  return(
    <></>
  )
}
