import React, {useState, useEffect} from "react";
import { Table, TableHead, TableContainer, Box, Paper, TableBody, TableRow, TableCell, Checkbox, TextField, Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";


const TableSection = styled.div`
    display: flex;
    justify-content: center;  
`

const TableMain = styled.div`
    width: 80%;
    max-width: 650px;
`

export default function ExtraTable(){

    const redirect = useNavigate();    
    //page self state 
    const [pState, setPState] = useState<"choosing" | "success" | "error">("choosing");

    //controller perparation 
    const [checked, setChecked] = useState<Array<boolean>>([])
    const [comments, setComments] = useState<Array<string>>([])
    const [deals, setDeals] = useState<Array<any>>([]);
    const [updater, triggerUpdater] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/all_addition`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : (localStorage.getItem("token") as string)
                }
            })

            res
                .json()
                .then(data => setDeals(data.data));
        }
        fetchData();
    },[])
    for(var i = 0; i < deals.length; i++){
        checked[i] = false;
        comments[i] = "";
    }
    

    


    //event handlers
    const submitHandler = async () => {
        //here we must prepare response to backend
        var order_string: string = ""
        for(var i = 0; i < deals.length; i++){
            if(checked[i] == true){
                order_string = order_string + deals[i].info + "-" + comments[i] + "\n";
            }
        }
        const url = `${process.env.REACT_APP_API_URL}index/send_mail_handler`;

        const body = {
            owner_id: (localStorage.getItem("id") as string),
            o_data : order_string,
            mail_type: "addition"
        }

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify(body)
        })

        res
            .json()
            .then(data => {
                if(data.res === 1){
                    setPState("success");
                    return;
                }
                setPState("error");
                return;
            });


    }

    const goHomeHandler = async () => {
        redirect("/");
    }


    //array of states (for change state of one array field i copy all of array data in some temp variable, change data field, and change state value to this temp variable)
    if(pState == "choosing"){
        return(
            <>
            <TableSection >
                <TableMain>
                <Box sx={{width: "100%"}}>
                    <Paper sx={{width:"100%", mb:2}}>
                        <TableContainer>
                            <Table 
                                aria-labelledby="tableTitle">
                                <TableBody>
                                    {deals.map((item: any, index: number) => {
                                        return(
                                            <TableRow key={index}>
                                                <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="warning"
                                                    onClick={() => {
                                                        var temp = checked
                                                        temp[index] = !(checked[index])
                                                        setChecked(temp);
                                                    }}
                                                    />
                                                </TableCell>
                                                <TableCell>{item.info}</TableCell>
                                                <TableCell><TextField  maxRows={10} multiline={true}  variant={"outlined"} label={"Комментарий"} sx={{width: "300px"}} color="warning" onChange={(e) => {
                                                    var temp = comments;
                                                    temp[index] = e.target.value;
                                                    setComments(temp);
                                                    
                                                }}/></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box>
                </TableMain>
                
            </TableSection>
            <Button variant="contained" color="error" onClick={submitHandler} style={{marginBottom: "10px"}}>Отправить</Button>
            </>
        )
    }
    if(pState == "error"){
        return(
            <>
                <h4>Что-то пошло не так</h4>
                <p>Попробуйте повротить попытку позже, если и это не поможет - свяжитесь с нами!</p>
                <Button variant="contained" color="error" onClick={goHomeHandler}>На главную</Button>
            </>
        )
    }
    if(pState =="success"){
        return(
            <>
                <h4>Ваш запрос отправлен менеджеру</h4>
                <p>В скором времени с вами свяжутся для финального подтверждения!</p>
                <Button variant="contained" color="error" onClick={goHomeHandler} >На главную</Button>
            </>
        )
    }
    return(<></>);
}