import React from 'react'
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import "./userCard.css";
import { Link } from 'react-router-dom';


import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import Paper from '@mui/material/Paper';

import { DocumentProvider } from '../../WordControl/DocumentProvider';
import {RiFileWord2Fill}  from "react-icons/ri";
import { Options } from '../../WordControl/DocxDeclaration';

type userCardType = {
  user_id: number,
  phone: string,
  email: string,
  user_name: string,
  password: string, 
  avatar: any,
  avatar_min: any,
  type: "tile" | "line",
  enable: any,
  manager_id: string,
  builder_id: string,
  pd: string
}
export default function AdminUserCard(props: userCardType) {

  // const exportWord = async () => {
  //     const saver = new DocumentProvider();
  //     const url = `${process.env.REACT_APP_API_URL}index/get_personal_data`;
  //     const res = await fetch(url, {
  //         method: "POST",
  //         headers: {
  //             Authorization: (localStorage.getItem("token") as string)
  //         },
  //         body: JSON.stringify({
  //             user_id: (props.user_id)
  //         })
  //     })
  //     res.json()
  //         .then(data => {
  //             if(data.res == 0){
  //               saver.SavePodryadDogovor(props.user_name, data.data, Options.BASE);
  //             }
  //         })

  // }


  var bgStyle: boolean = true
  if(props.enable === "0"){
    bgStyle = false;
  }
  
  var fullLoaded: boolean = true;
  if(props.manager_id === null || props.builder_id === null){
    fullLoaded = false; 
  }

  var pdfilled: boolean = true;
  if(props.pd === "0"){
    pdfilled = false; 
  }



  if(props.type === "tile"){
    return (
      
      <div className='user-card' style={{backgroundColor: bgStyle ? "#ececec" : "#7e7e7e"}}>
          <h4 className='tile-text'>{props.user_name}</h4>
          <p className='tile-text'>Телефон: {props.phone}</p>
          <p className='tile-text'>Почта: {props.email}</p>
          {fullLoaded === true ? <p>&#8203;</p> : <p style={{color: "red", marginBottom: "16px"}} className={"tile-text"}>Небходимо указать прораба</p>}
          {pdfilled === true ? <p>&#8203;</p> : <p style={{color: "orange", marginBottom: "16px"}} className={"tile-text"}>Не заполнены персональные данные</p>}
          <div className='btn-section'>
            {/* <Button variant="warning" className='btn-manager' >Редактировать</Button>
            <Button variant='danger' className='btn-manager' onClick={deleteHandler}>Удалить</Button> */}
          
            <Link to={`/user/${props.user_id}`}>
              <Button variant='info' className='btn-manager' style={{width: "100%"}}>
                  Подробнее
              </Button>
            </Link>
            <Link to={`/documents/${props.user_id}`}>
              <Button className='btn-manager' style={{width: "100%"}}>
                Документы <RiFileWord2Fill></RiFileWord2Fill>
              </Button>
            </Link>
          </div>
      </div>
    )
    }
    if(props.type === "line"){
      return(
        <TableRow>
            <TableCell>
              <h6 >{props.user_name}</h6>
            </TableCell>
            <TableCell align="right">
              <p >{props.phone}</p>
            </TableCell>
            <TableCell align="right">
              <p >{props.email}</p>
            </TableCell>
            <TableCell align="right">
              {fullLoaded === true ? <DoneIcon/> : <ClearIcon/>}
            </TableCell>
            <TableCell align="right">
              {pdfilled === true ? <DoneIcon/> : <ClearIcon/>}
            </TableCell>
            <TableCell align="right">
              <div className='btn-section'>
              <Link to={`/user/${props.user_id}`} >
                <Button variant='info' className='btn-manager' style={{width: "100%"}}>
                    Подробнее
                </Button>
                </Link>
                <Link to={`/documents/${props.user_id}?name=${props.user_name}`}>
                  <Button className='btn-manager' style={{width: "100%"}}>
                    Документы <RiFileWord2Fill></RiFileWord2Fill>
                  </Button>
                </Link>
              </div>
            </TableCell>
        </TableRow>
      )
    }

    return(
      <></>
    )
}
