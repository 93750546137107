type tileProps = {
    image: any
    title: string,
    desc: string,
    redirect: string
}
var AdminButtons: Array<tileProps> = [   
    {
        title: "Создать пользователя",
        desc: "Ручное добавление пользователя", 
        image: require("../../../static/images/wizard_dark.webp"),
        redirect: "user-creation"
    }
    // ,{
    //     title: "Контакты руководства",
    //     desc: "Изменить контакты руководства", 
    //     image: require("../../../static/images/wizard_dark.webp"),
    //     redirect: "admin-contact-edit"
    // }
    ,{
        title: "Документация к API",
        desc: "Информация для интеграции", 
        image: require("../../../static/images/lawyer_dark.webp"),
        redirect: "api-dock"
    }
    // ,{
    //     title: "Список сотрудников",
    //     desc: "", 
    //     image: require("../../../static/images/contact_neuro_dark.png"),
    //     redirect: "employes"
    // }
    ,{
        title: "Дополнительные услуги",
        desc: "управление дополнительными услугами", 
        image: require("../../../static/images/contact_neuro_dark.webp"),
        redirect: "admin-extra"
    }
]

export {AdminButtons}
