import React from "react"
import "./image-style.css"
import styled from "styled-components"
import MainTile from "../../Components/MainTile/Tile"
import TileOutside from "../MainTile/TileOutside"

import { TileLineItems } from "./TileLineItems";


const TileLine = styled.section<{bg: any}>`
    width: fit-content;
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    background-color: var(--bs-gray-100);
    background-image: url(${props => props.bg});
    padding-top: 20px;
    padding-bottom: 20px;
`

const TileWrap = styled.div`
    display: flex;
    width: 100%;
    
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap; 
`

export default function TileSection(props : any){
    const bg = "";
    //const bg = require("../../static/images/bg.png");
    return(
        <TileLine bg={bg}>
                <TileWrap>
                    {TileLineItems.map((item: any, index: number) => {
                        if((item.redirect).includes("http")){
                            return(
                                <TileOutside descWidth={"200px"} title={item.title} desc={item.desc} image={item.image} key={index} redirect={item.redirect}></TileOutside>
                            )
                        }else{
                           return(
                                <MainTile descWidth={"200px"} title={item.title} desc={item.desc} image={item.image} key={index} redirect={item.redirect} ></MainTile>
                            ) 
                        }
                    })}
                </TileWrap>
        </TileLine>
    )
}