import React, {useState, useEffect} from 'react'
import ContactTemplate from '../../Components/HelperPageInfo/HelperInfo'

export default function BuilderPage() {
    
    const [builder, setBuilder] = useState<any>(null);
    useEffect(() => {
      
      const getData = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/get_builder_by_user_id`;
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Authorization" : (localStorage.getItem("token") as string)  
          },
          body : JSON.stringify({
            user_id: (localStorage.getItem("id") as string)
          })
        })
        res
          .json()
          .then(data => setBuilder(data.data));

      }
      getData();
    }, [])
    if(builder){
      return (
        <div className='helper-index'>
            <h2 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "var(--bs-gray-150)", margin: 0}}>Ваш прораб</h2>
            <ContactTemplate phone={builder.phone} user_name={builder.user_name} email={builder.email} image={builder.avatar}/>
        </div>
      )
    }
    return(
      <div className='helper-index'>
        <h2 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "var(--bs-gray-150)", margin: 0}}>За вами еще не закреплен прораб</h2>
        <h5 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "white", margin: 0}}>Скоро здесь появится контактная ифнормация</h5>
      </div>
    )
}
