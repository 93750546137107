import React, { useEffect, useState } from 'react'
import { Button, InputGroup, Form } from 'react-bootstrap';
import {useNavigate, useParams } from 'react-router'
import styled from 'styled-components';

//icons 
import {RiFileWord2Fill} from "react-icons/ri";

import { DocumentProvider } from '../../WordControl/DocumentProvider';
import { Options } from '../../WordControl/DocxDeclaration';


const IndexUser = styled.div`
    
`

type userCardType = {
    user_id: number,
    login: string,
    email: string,
    phone: string,
    password: string, 
    avatar: any,
    avatar_min: any,
    user_name: string,
    enabled: string,
    builder_id: any,
    manager_id: any
}
export default function AdminCurrentUser() {

    const [data, setData] = useState<null | userCardType>(null);
    const [mode, setMode] = useState<"reading" | "editing"| "deleted">("reading");
    const [deleted, setDeleted] = useState<boolean>(false);

    const [name, setName] = useState("");
    const [Id, setId] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [login, setLogin] = useState("");

    //managers and builders data
    const [managers, setManagers] = useState<any>(null);
    const [builders, setBuilders] = useState<any>(null);
    const [selectedManager, setSelectedManager] = useState("НЕОБХОДИМО ВЫБРАТЬ!");
    const [selectedBuilder, setSelectedBuilder] = useState("НЕОБХОДИМО ВЫБРАТЬ!");

    //money and date data 
    const [moneyTotal, setMoneyTotal] = useState<string>("");
    const [moneyPayed, setMoneyPayed] = useState<string>("");
    const [dateStart, setDateStart] = useState<string>("");
    const [dateEnd, setDateEnd] = useState<string>("");

    const [personalData, setPersonalData] = useState(null);

    const navigate = useNavigate();
    const parametrs = useParams();

    


    const exporWord =  (): void => {
        if(personalData !== null){ 
            const saver = new DocumentProvider();
            saver.SavePodryadDogovor(name, personalData, Options.BASE);
        }
    }

    const getWord = () => {
        const url = `${process.env.REACT_APP_WORD_COMPRESSOR}documents/${Id}`;
        fetch(url); 
    }

    const rewindHandler = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/rewind_user`;
        const body = {
            id : parametrs.id
        }
        const res = await fetch(url, {
            method: "POST",
            headers : {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify(body)
        })
        res
            .json()
            .then(data => setDeleted(true))

    }

    const deleteHandler = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/del_user`
        const reqBody = {
            id: parametrs.id
        };
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Authorization" : (localStorage.getItem("token") as string)
          },
          body: JSON.stringify(reqBody)
        })
        res
          .json()
          .then(() => setMode("deleted"))
        
    }

    const changeContractData = async () => {
        
        var monPayed: string | null = moneyPayed;
        var monTotal: string | null = moneyTotal;
        
        if(parseInt(monPayed) == null || parseInt(monTotal) == null){
            monPayed = null;
            monTotal = null;
        }

        if(monPayed == "Не указано" || monTotal == "Не указано"){
            monPayed = null;
            monTotal = null;
        }
        
        var dStart: any = dateStart;
        var dEnd: any = dateEnd; 
        if(dStart === "Не указано"){
            dStart = null;
            
        }
        if(dEnd === "Не указано"){
            dEnd = null;
        }

        const url = `${process.env.REACT_APP_API_URL}index/set_contract_data`;
        const body = {
            money_total: monTotal,
            money_payed: monPayed,
            date_start: dStart,
            date_end: dEnd,
            user_id: (parametrs.id as string)
        }
        const res = await fetch(url, {
            method: "POST",
            headers : {
                "Authorization": (localStorage.getItem("token") as string)
            },
            body: JSON.stringify(body)

        })
        
        res 
        .json()
        .then(data => {});
        
        

    }

    const changeManagerAndBuilder = async () => {
        var builderId = "0";
        //находим билдера с соответствующим id
        builders.map((item: any) => {
            if(item.user_name === selectedBuilder){
                builderId = item.user_id;
            }
        })

        var managerId = "0";
        //находим манагера с соответствующим id
        managers.map((item: any) => {
            if(item.user_name === selectedManager){
                managerId = item.user_id;
            }})
         
        const managerBody = {
            user_id: Id,
            manager_id: managerId
        }
        const builderBody = {
            user_id: Id,
            builder_id: builderId
        }

        const urlManager = `${process.env.REACT_APP_API_URL}index/pin_manager`;
        if(managerBody.manager_id !== "0"){
            const managerRes = await fetch(urlManager, {
                method: "POST",
                headers : {
                    "Authorization": (localStorage.getItem("token") as string)
                },
                body: JSON.stringify(managerBody)
            })

                
        }

        const urlBuilder = `${process.env.REACT_APP_API_URL}index/pin_builder`;
        if(builderBody.builder_id !== "0"){
            const builderRes = await fetch(urlBuilder, {
                method: "POST",
                headers : {
                    "Authorization": (localStorage.getItem("token") as string)
                },
                body: JSON.stringify(builderBody)
            })
        


        }
    }

    const changeHandler = async () => {
        //че тут вообще происходит 
        //кароче, зачем-то я начал делать прикрепление менеджера и прораба к пользователю через отдельные эндпоинты
        //
        await changeManagerAndBuilder();
        await changeContractData();
        const url = `${process.env.REACT_APP_API_URL}index/edit_user`
        const body = {
            id: Id,
            login: login,
            user_name: name,
            email: mail,
            phone: phone,
        }

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify(body)
        })

        

        setMode("reading");

    }

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}index/get_user`;
        const reqBody = {
            id: (parametrs.id as string)
        }
        
        async function getPD(){
            const url = `${process.env.REACT_APP_API_URL}index/get_personal_data`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: (localStorage.getItem("token") as string)
                },
                body: JSON.stringify({
                    user_id: (parametrs.id as string)
                })
            })
            res.json()
                .then(data => {
                    if(data.res == 0){
                        setPersonalData(data.data);
                    }
                })
        }
        
        async function getManagers(){
            const url = `${process.env.REACT_APP_API_URL}index/get_all_manager`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: (localStorage.getItem("token") as string)
                },
                
            })
            return await res.json()
                
        }
        
        async function getBuilders(){
            const url = `${process.env.REACT_APP_API_URL}index/get_all_builder`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: (localStorage.getItem("token") as string)
                }
            })
            return await res.json()
        }

        async function getContractData() {
            const url = `${process.env.REACT_APP_API_URL}index/get_contract_data`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": (localStorage.getItem("token") as string)
                },
                body: JSON.stringify({
                    user_id: (parametrs.id as string)
                })
            }); 
            return await res.json();
        
        }


        async function getData(){
            const data = await fetch(url, {
                method: "POST",
                headers : {
                    "Authorization" : localStorage.getItem("token") as string
                },
                body: JSON.stringify(reqBody)
            })
            
            data
                .json()
                .then(data => data.data)
                .then((data: userCardType) => {
                    setLogin(data.login);
                    setName(data.user_name);
                    setId((data.user_id).toString());
                    setMail(data.email);
                    setPhone(data.phone);
                    setData(data);

                    if(data.enabled === "0"){
                        setDeleted(false);
                    }else{
                        setDeleted(true);
                    }
                                 
                    getManagers()
                        .then(managers => managers.data)
                        .then(managers => {
                            if(data.manager_id !== null){
                                managers.forEach((manager: any, index: any) => {
                                    if(manager.user_id === data.manager_id){
                                        setSelectedManager(manager.user_name);
                                    }    
                                })

                            }
                            setManagers(managers);
                    })
                    
                    
                    getBuilders()
                        .then(builders => builders.data)
                        .then(builders => {
                            if(data.builder_id !== null){
                                builders.forEach((builder: any, index: any) => {
                                    if(builder.user_id === data.builder_id){
                                        setSelectedBuilder(builder.user_name);
                                    }   
                                })
                            }
                            setBuilders(builders)
                    })
                    
                    getContractData()
                        .then(res => {
                            if(res.res === -1){
                                setDateStart("Не указано");
                                setDateEnd("Не указано");
                                setMoneyPayed("Не указано");
                                setMoneyTotal("Не указано");
                                return;
                            }
                            setDateStart(res.data.date_start);
                            setDateEnd(res.data.date_end);
                            setMoneyPayed(res.data.money_payed);
                            setMoneyTotal(res.data.money_total);
                        });
                    
                    getPD();
                
                })
        }


        getData();
    }, [])
    if(data){
        if(mode === "deleted"){
            return(
                <IndexUser>
                    <h2>Пользователь успешно удален</h2>
                    <Button onClick={() => {navigate(-1);}}>К списку пользователей</Button>
                </IndexUser>
            )
        }
        if(mode === "reading" && managers !== null && builders !== null){
            return(
                <IndexUser>
                    <div className='container'>
                        <Form.Group>
                            <Form.Label>Почта: </Form.Label>
                            <Form.Control disabled onChange={(e)=>{setMail(e.target.value)}} value={mail}></Form.Control>
                        </Form.Group>
                        <div className='row' style={{display: "flex", justifyContent: "center"}}>
                            <div className='col-6'>
                                <Form.Group>
                                    <Form.Label>ФИО: </Form.Label>
                                    <Form.Control disabled onChange={(e)=>{setName(e.target.value)}} value={name}></Form.Control>
                                </Form.Group>
                                
                                <Form.Group>
                                    <Form.Label>Прораб: {selectedBuilder}</Form.Label>  
        
                                    <select className="form-select form-select-sm app-form-select mb-2" disabled>
                                    <option>НЕОБХОДИМО УКАЗАТЬ! ПЕРЕЙДИТЕ В РЕЖИМ РЕДАКТИРОВАНИЯ!</option>
                                    {builders.map((item: any, index: number) => {
                                        if(item.user_name === selectedBuilder){
                                            return(
                                                <option value="" selected key={index}>{item.user_name}</option>    
                                            )
                                        }
                                        return(
                                            <option value="" key={index}>{item.user_name}</option>
                                        )
                                    })}
                                    </select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Дата начала ремонта: </Form.Label>
                                    <input disabled type="date" className="form-control" value={dateStart}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Сумма договора</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">₽</InputGroup.Text>
                                        <Form.Control
                                            disabled
                                            value={moneyTotal}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                </div>
                                <div className='col-6'>
                                <Form.Group>
                                    <Form.Label>Номер телефона: </Form.Label>
                                    <Form.Control disabled onChange={(e)=>{setPhone(e.target.value)}} value={phone} ></Form.Control>
                                </Form.Group>
                                
                                <Form.Group>
                                    <Form.Label>Менеджер: {selectedManager}</Form.Label>  
                                    <select className="form-select form-select-sm app-form-select mb-2" disabled>
                                        <option>НЕОБХОДИМО УКАЗАТЬ! ПЕРЕЙДИТЕ В РЕЖИМ РЕДАКТИРОВАНИЯ!</option>
                                    {managers.map((item: any, index: number) => {
                                        if(item.user_name === selectedManager ){
                                            return(
                                                <option  selected key={index}>{item.user_name}</option>
                                            )
                                        }
                                        return(
                                            <option  key={index}>{item.user_name}</option>
                                        )
                                    })}
                                    </select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Дата окончания ремонта: </Form.Label>
                                    <input disabled type="date" className="form-control" value={dateEnd}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Денег внесено</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">₽</InputGroup.Text>
                                        <Form.Control 
                                            disabled
                                            value={moneyPayed}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                
                            </div>
                        </div>
                        <div className='row' style={{display: "flex", justifyContent: "center"}}>
                            <div className='col-6'>
                                
                                <Button onClick={() => {
                                    navigate(-1);
                                }} className='btn-manager' style={{margin: "5px"}}>Назад</Button>
                                <Button variant="warning" className='btn-manager' onClick={() => {setMode("editing")}} style={{margin: "5px"}}>Редактировать</Button>
                                <Button variant='danger' className='btn-manager' onClick={deleteHandler} style={{margin: "5px"}}>Удалить</Button>
                                <Button variant={"info"} className='btn-manager' disabled= {deleted} onClick={rewindHandler} style={{margin: "5px"}}>Восстановить</Button>
                                <Button className='btn-manager' onClick={() => {exporWord()}} style={{margin: "5px"}}>Экспортировать договор в Word <RiFileWord2Fill style={{marginBottom: "3px"}}/></Button>
                                <a href={`http://localhost:3030/documents/${Id}_${name.split(" ").join("_")}_Договор_подряда.docx`}><Button>Скачать ворд</Button></a>
                            </div>
                        </div>
                    </div>
                    {/* <h2>Персональные данные клиента</h2> */}
                    {/* <ContactForm id={(parametrs.id as string)} btntxt={"Изменить данные"} pdAgree={false}/> */}
                </IndexUser>
            )   
        }
        if(mode ==="editing" && managers !== null && builders !== null){
            return(
                <IndexUser>
                    <div className='container'>
                        <Form.Group>
                            <Form.Label>Почта: </Form.Label>
                            <Form.Control onChange={(e)=>{setMail(e.target.value)}} value={mail}></Form.Control>
                        </Form.Group>
                        <div className='row' style={{display: "flex", justifyContent: "center"}}>
                            <div className='col-6'>
                                <Form.Group>
                                    <Form.Label>Имя: </Form.Label>
                                    <Form.Control onChange={(e)=>{setName(e.target.value)}} value={name}></Form.Control>
                                </Form.Group>
                                
                                <Form.Group>
                                    <Form.Label>Прораб: </Form.Label>  
                                    <select className="form-select form-select-sm app-form-select mb-2" onChange={(e) => {setSelectedBuilder(e.target.value)}}>
                                    <option>НЕОБХОДИМО УКАЗАТЬ! ПЕРЕЙДИТЕ В РЕЖИМ РЕДАКТИРОВАНИЯ!</option>
                                        {builders.map((item: any, index: number) => {
                                            if(item.user_name === selectedBuilder){
                                                return(
                                                    <option selected key={index}>{item.user_name}</option>
                                                )
                                            }
                                            return(
                                                <option key={index}>{item.user_name}</option>
                                            )
                                        })}
                                    </select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Дата начала ремонта: </Form.Label>
                                    <input  type="date" className="form-control" onChange={(e) => setDateStart(e.target.value)} value={dateStart}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Сумма договора</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">₽</InputGroup.Text>
                                        <Form.Control
                                            value={moneyTotal}
                                            onChange={(e) => {setMoneyTotal(e.target.value)}}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className='col-6'>
                                <Form.Group>
                                    <Form.Label>Номер телефона: </Form.Label>
                                    <Form.Control onChange={(e)=>{setPhone(e.target.value)}} value={phone} ></Form.Control>
                                </Form.Group>
                                
                                <Form.Group>
                                    <Form.Label>Менеджер: </Form.Label>  
                                    <select className="form-select form-select-sm app-form-select mb-2"  onChange={(e) => {setSelectedManager(e.target.value)}} >
                                    <option>НЕОБХОДИМО УКАЗАТЬ! ПЕРЕЙДИТЕ В РЕЖИМ РЕДАКТИРОВАНИЯ!</option>
                                        {managers.map((item: any, index: number) => {
                                        if(item.user_name === selectedManager){
                                            return(
                                                <option selected>{item.user_name}</option>
                                            )
                                        }
                                        return(
                                            <option key={index}>{item.user_name}</option>
                                        )
                                    })}
                                    </select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Дата окончания ремонта: </Form.Label>
                                    <input  type="date" className="form-control" onChange={(e) => setDateEnd(e.target.value)} value={dateEnd}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Денег внесено</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">₽</InputGroup.Text>
                                        <Form.Control 
                                            onChange={(e) => {setMoneyPayed(e.target.value)}}
                                            value={moneyPayed}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row' style={{display: "flex", justifyContent: "center"}}>
                            <div className='col-6'>
                                <Button variant="warning" className='btn-manager ' onClick={() => {setMode("reading")}} >Отмена</Button>
                                <Button variant="success" className='btn-manager ml-10' onClick={changeHandler} >Подтвердить</Button>
                            </div>
                        </div>
                    </div>
                    {/* <h2>Персональные данные клиента</h2> */}
                    {/* <ContactForm id={(parametrs.id as string)} /> */}
                </IndexUser>
            )
        }
    }
    return (
        <div>AdminCurrentUser</div>
    )
}
