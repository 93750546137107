import React from 'react'

export default function AdminAPIDock() {
  return (
    <div>
        <pre style={{textAlign: "left"}}>
         {`Интеграция с API 
        Для получения таких вещей как список пользователей или для создания нового пользователя необходимо получить токен авторизовавшись как администратор
        Тестовый пользователь с правами администратора 
        login: admin 
        pass: admin
        Необоходимо войти, и создать нового пользователя с правами адмнистратора, сделать это можно непосредствено в лк 
        После создания нового пользователя с надежным паролем удалить тестового пользователя 
        Далее, имея в базе пользователя, используй его токен для отправки запросов 
        1. Получение токена
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_all_manager";
        {
            method: "POST",
            body: {
                username: login,
                password: password
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : token, ##не обязательно только для этого запроса
            }
        }

        (Тест токена)
        "http://91.245.210.84/metry_api/index.php/api_ng/index/say_hello";
        {
            method: "POST",
            header: {
                "Authorization" : token,
            }
        }
        Должен вернуть "privet";

        2. Получение списка пользователей
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_users";
        {
            method: "GET",
            headers: {
                "Authorization" : token,
            }
        }
        
        3.Добавление пользователя
        "http://91.245.210.84/metry_api/index.php/api_ng/index/add_user";
        {
            method: "POST",
            headers: {
                "Authorization" : token,
            },
            body : {
                login: string,
                password: sring,
                user_name: string,
                email: string, 
                phone: string, 
                user_role: "admin" | "user",
                manager_id: uint (не обязательно), 
                builder_id: uint (не обязательно)
            }

            manager_id или builder_id можно найти в базе данных main.users 

        }

        В отсутствие доступа к бд имея токен администратора можно получить список менеджеров 
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_all_manager";
        Список прорабов(builder_id) 
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_all_builder";
        
        {
            method: "POST",
            headers: {
                "Authorization" : token,
            },
        }
        
        Получить менеджера по id пользователя 
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_manager_by_user_id";
        
        {
            method: "POST", 
            headers: {
                "Authorization" : token,
            },
            body: {
                user_id: uint
            }
        }
        Получить прораба по id пользователя
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_manager_by_user_id";
        
        {
            method: "POST",
            headers: {
                "Authorization" : token,
            },
            body: {
                user_id: uint
            }
        }

        Получить персональные данные по id пользователя
        "http://91.245.210.84/metry_api/index.php/api_ng/index/get_personal_data";
        {
            method: "POST", 
            headers: {
                "Authorization" : token,
            },
            body: {
                user_id: uint
            }
        }

        Обработка исключений по персональным данным
        "res" : 1 --> Польователь не заполнил персональные данные
        "res" : -1 --> Пользователь с таким id не найден 
        "res" : 0 --> Успех  


        Установить или изменить персональные данные 
        "http://91.245.210.84/metry_api/index.php/api_ng/index/set_personal_data";
        {
            method: "POST", 
            headers: {
                "Authorization" : token,
            },
            body: {
                full_name: string,
                mail: string,
                birth_date: string, 
                passport: string,
                whos_get: string,
                code_podr: string,
                reg_address: string,
                living_address: string,
                jk: string,
                object_address: string,
                object_area: uint,
                rooms: uint,
                toilets: uint, 
                owner_id: uint,
                pasport_date: string, 
            }
        }
        codes: 
            "res": 1 --> Пользователь с таким id не существует
            "res" : 0 --> Успех
        

        `
        
        

        }
        
        </pre>
    </div>
  )
}
