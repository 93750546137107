import React, {useState} from "react";
import "./statics.css"
import UserProfile from "../../Components/Profile/UserProfile";
import RemainCounter from "../../Components/RemainCounter/RemainCounter";
import RemainCounterDaysOnly from "../../Components/RemainCounter/RemainCounterDaysOnly";
import RemainCounter2 from "../../Components/RemainCounter/RemainCounter2";

export default function ProfilePage(){
    const CounterSection = () =>{
        const [expand, setExpand] = useState<boolean>(false);        
        if(expand){
            return(
                <div onClick={() => {setExpand(false)}} className="counter">
                    <RemainCounter2 />
                </div>
            )
        }
        return(
            <div onClick={() => {setExpand(true)}} className="counter"> 
                <RemainCounter2 />
            </div>
        )
    }
    return(
        <div className="profile-main">
            
            <UserProfile/>
            
            <CounterSection></CounterSection>           
            
        </div>
    )
}