import { DockModules } from "./DockModules";
import { DockConfig } from "./DockConfig";
import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  Footer,
  Header,
  HeadingLevel,
  LevelFormat,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun
} from "docx";
import {
  Options
} from "./DocxDeclaration"



export class DocumentCreator {

    private dockConstructor =  new DockModules();
    private dockConfig = new DockConfig();

    public  createAgreement(personalData: any, Options: Options) : Document {

      var user_name = personalData.full_name;
      var object_address = personalData.object_address;
      //Подготовка данных для вставки в документ
      var tarife_name = Options;
    

      const document = new Document({
        
        creator: "realniceping",
        description: "Первая попытка сверстать word документ через javascript",
        title: "Договор об оказании услуги",
        lastModifiedBy: "realniceping",
        revision: 0,
        compatibility:{
          version: 15,
        },
        numbering: this.dockConfig.baseNumberingStyles,
        styles: this.dockConfig.baseStyles,
        sections: [
          {
            headers: {
              first: this.dockConstructor.defaultHeader(),
            },
            footers: {
              first: this.dockConstructor.defaultFooter(),
              default: this.dockConstructor.defaultFooter(),
            },
            properties: this.dockConfig.sectionPropertiesDefault,
            children: [
              this.dockConstructor.createHeaderCenter("Договор подряда"),
              this.dockConstructor.createHeaderCenter(`на строительно-отделочные работы № ____-ДПФ`),
              this.dockConstructor.createCityAndData(25, 10, 2023, "г.Тюмень"),
              this.dockConstructor.createBlankParagraph(),
              this.dockConstructor.createBlankParagraph(),
              this.dockConstructor.createText(`\tОбщество с ограниченной ответственностью «Метры», именуемое в дальнейшем «Подрядчик», в лице генерального директора Андреева Антона Евгениевича, действующего на основании Устава, с одной стороны, и ${user_name}, именуемый в дальнейшем «Заказчик», с другой стороны, совместно именуемые «Стороны», заключили настоящий договор о нижеследующем:`),
              this.dockConstructor.createHeaderL0("Термины и определения"),
              this.dockConstructor.createBlankParagraph(),
              this.dockConstructor.createText(` \tВ настоящем Договоре следующие слова и выражения имеют значения, определяемые ниже:`),
              this.dockConstructor.createBulletL1(`«Заказчик» – физическое лицо, действующее в соответствии с законодательством РФ.`),
              this.dockConstructor.createBulletL1(`«Подрядчик» – Общество с ограниченной ответственностью «Метры», созданное и действующее в соответствии с законодательством РФ.`),
              this.dockConstructor.createBulletL1(`«Стороны» Заказчик и Подрядчик.`),
              this.dockConstructor.createBulletL1(`«Договор» – настоящий документ, подписанный Сторонами (уполномоченными представителями сторон), включая все приложения, изменения и дополнения, которые могут быть подписаны Сторонами в период действия Договора.`),
              this.dockConstructor.createBulletL1(`«Объект работ» (далее "Объект") – помещение, расположенное по адресу: (г. Тюмень, ${object_address}).`),
              this.dockConstructor.createBulletL1(`«Результат работ» – выполненные в соответствии с Договором работы на Объекте, которые подлежат передаче Подрядчиком Заказчику по окончании действия Договора, и на которые, в соответствии с условиями Договора, будет установлен гарантийный срок.`),
              this.dockConstructor.createBulletL1(`«Акт сдачи-приемки выполненных работ» (далее - "Акт сдачи-приемки") – документ, подписанный Сторонами, свидетельствующий об окончании Работ в целом и о приемке Результата работ Заказчиком, согласно условиям Договора.`),
              this.dockConstructor.createBulletL1(`«Акт о приемке выполненных работ по этапу» – документ, подписываемый Сторонами о выполненном Подрядчиком объеме работ по определенному этапу.`),
              this.dockConstructor.createBulletL1(`"Представитель Заказчика" – лицо и/или лица, надлежащим образом уполномоченные Заказчиком выступать от имени Заказчика, имеющие доверенность на совершение определенных действий по Договору.`),
              this.dockConstructor.createBulletL1(`"Представитель Подрядчика" – лицо и/или лица, надлежащим образом уполномоченные Подрядчиком выступать от имени Подрядчика, имеющие доверенность на совершение определенных действий по Договору.`),
              this.dockConstructor.createHeaderL0(`Предмет договора`),
              this.dockConstructor.createBlankParagraph(),
              this.dockConstructor.createTextL1(`Подрядчик в соответствии с техническим заданием Заказчика (либо Техническим проектом) и перечнем работ и материалов по пакету «${tarife_name}» (Приложение №1 и Приложение №2 к настоящему Договору) обязуется собственными и/или привлеченными силами, в установленный настоящим Договором срок, осуществить комплекс работ по чистовой отделке Объекта, находящегося по адресу: `), 
              this.dockConstructor.createTableBuildingObject(personalData),
              this.dockConstructor.createText(`принадлежащего Заказчику на основании права собственности, включая необходимые монтажные, отделочные, инженерные и иные Работы и сдать результат указанных работ Заказчику, а Заказчик обязуется создать Подрядчику необходимые условия для выполнения работ, принять результат работ и оплатить его на условиях настоящего договора.`),
              this.dockConstructor.createHeaderL0(`Сроки выполнения работ`),
              this.dockConstructor.createBlankParagraph(),
              this.dockConstructor.createTextL1(`Общий срок выполнения Подрядчиком всех Работ в полном объеме, в соответствии с условиями Договора и сдачи Результата работ Заказчику составляет 45 рабочих дней, с момента получения ключей (ключи передаются по двустороннему подписанному Акту приема-передачи), а также внесения авансового платежа по Договору и предоставления Подрядчику итогового технического задания.`),
              this.dockConstructor.createTextL1(`В течение 3х рабочих дней с момента поступления авансового платежа, передачи ключей и предоставления итогового технического задания, Подрядчик приступает к выполнению работ.`),
              this.dockConstructor.createTextL1(`Изменения в сроках, прописанных в п. 3.1. Договора, оформляются сторонами Договора путем подписания Дополнительного соглашения, которое будет являться неотъемлемой частью настоящего договора`),
              this.dockConstructor.createHeaderL0(`Права и обязанности Сторон`),
              this.dockConstructor.createBlankParagraph(),
              this.dockConstructor.createHeaderLeft(`Заказчик вправе`),
              this.dockConstructor.createTextL1(`В любое время проверять ход и качество Работ, не вмешиваясь в деятельность Подрядчика.`),

              this.dockConstructor.createTextL1(`Отказаться от исполнения Договора и потребовать возмещения убытков, если Подрядчик не приступает своевременно к исполнению Договора или выполняет Работы настолько медленно, что окончание ее к сроку, установленному в Договоре, становится явно невозможным.`),
              this.dockConstructor.createTextL1(`Назначить Подрядчику разумный срок (общепринятый срок для производства аналогичных работ) для устранения недостатков (оформляется в письменном виде) и при неисполнении Подрядчиком в назначенный срок этого требования отказаться от Договора либо устранить недостатки своими силами или поручить устранение недостатков третьему лицу с отнесением расходов на Подрядчика, а также потребовать возмещения убытков, если во время выполнения Работ станет очевидным, что она не будет выполнена надлежащим образом.`),
              this.dockConstructor.createTextL1(`По своему выбору в случаях, когда Работы выполнены Подрядчиком с отступлениями от Договора, ухудшившими результат Работ, или с иными недостатками, которые делают его не пригодным для предусмотренного в Договоре использования либо непригодности для обычного использования: `),
              this.dockConstructor.createBulletL1(`потребовать от Подрядчика замены результата Работ ненадлежащего качества (повторного выполнения Работ);`),
              this.dockConstructor.createBulletL1(`потребовать от Подрядчика безвозмездного устранения недостатков в разумный срок;`),
              this.dockConstructor.createBulletL1(`потребовать от Подрядчика соразмерного уменьшения установленной за Работы цены;`),
              this.dockConstructor.createBulletL1(`устранить недостатки своими силами или привлечь для их устранения третье лицо с отнесением расходов на устранение недостатков на Подрядчика.`), 

              this.dockConstructor.createTextL1(`Если отступления в Работах от условий Договора или иные недостатки результата Работ в установленный Заказчиком разумный срок не были устранены либо являются существенными и неустранимыми, отказаться от исполнения Договора и потребовать возмещения причиненных убытков.`),
              this.dockConstructor.createTextL1(`Требовать безопасного и эффективного использования результата Работ.`),
              this.dockConstructor.createTextL1(`В случае обнаружения недостатков во время приемки результата Работ или после его приемки в течение гарантийного срока, а если он не установлен, - разумного срока:`),
              this.dockConstructor.createBulletL1(`потребовать от Подрядчика безвозмездного повторного выполнения Работ;`),
              this.dockConstructor.createBulletL1(`потребовать от Подрядчика безвозмездного устранения недостатков в разумный срок; `),
              this.dockConstructor.createBulletL1(`потребовать от Подрядчика соразмерного уменьшения установленной за Работы цены;`),
              this.dockConstructor.createTextL1(`Заказчик имеет право в рамках договора привлечь специалиста Подрядчика в процесс приёмки выполненных работ на Объекте.`),
              this.dockConstructor.createHeaderLeft(`Обязанности Заказчика: `),
              this.dockConstructor.createTextL1(`В течение 7 дней со дня подписания Акта приема-передачи ключей от объекта, предоставить Подрядчику итоговое техническое задание.`),
              this.dockConstructor.createTextL1(`До начала работ обеспечить Подрядчика свободным доступом на Объект.`),
              this.dockConstructor.createTextL1(`Контролировать сроки и качество выполняемых работ в рабочие часы Подрядчика, проверять ход и качество работы, выполняемой Подрядчиком, не вмешиваясь при этом в его оперативно-хозяйственную деятельность.`),
              this.dockConstructor.createTextL1(`Принять результаты выполненных Подрядчиком работ.`),
              this.dockConstructor.createTextL1(`Оплатить работы, выполненные Подрядчиком, в соответствии с п. 8 Договора.`),
              this.dockConstructor.createTextL1(`На период выполнения Работ обеспечить Подрядчика точками подключения электрической энергии необходимой мощности и водой.`),
              this.dockConstructor.createTextL1(`Исполнить в полном объеме все свои обязательства, предусмотренные в других статьях настоящего договора.`),
              this.dockConstructor.createHeaderLeft(`Обязанности Подрядчика: `),
              this.dockConstructor.createTextL1(``),
              this.dockConstructor.createTextL1(``),
              this.dockConstructor.createTextL1(``),
              this.dockConstructor.createTextL1(``),
              this.dockConstructor.createTextL1(``),

            ]
          }
        ]
      });
      return document;
    }

 
  }
  