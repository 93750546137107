import react from "react"
import { useState, useEffect } from "react"
import EmployeTile from "../../Components/AdminEmployesTile/EmployeTile";

type emplType = {
    user_name: string,
    role: string
}
export default function AdminAllEmployes(){

    const [data, setData] = useState<Array<emplType>>([]);

    useEffect(() => {
        const fetchData = async () => {
            const url = "http://91.245.210.84/metry_api/index.php/api_ng/index/get_all_employe";
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : (localStorage.getItem("token") as string)
                }
            })

            res
                .json()
                .then(data => setData(data.data));
        }

        fetchData();

    }, [])

    return(
        <div>
            {data.map((item: any, index: number) => {
                return(
                    <EmployeTile name={item.user_name} role={item.name}/>
                )
            })}
        </div>
    )
}