import React, {useState} from 'react'
import MainTile from '../../Components/MainTile/Tile'
import "./selection.css"

const temp = require("../../static/images/extra_dark.webp")
const base = require("../../static/images/default.png")
const average = require("../../static/images/average.png")
const expensive = require("../../static/images/amazing.png")

export default function SelectionPage() {
    const [selected, setSelected] = useState<"base" | "comfort" | "prestige" | "no_select">("no_select");
    
    type aboveSelectorProp = {
        state: "base" | "comfort" | "prestige" | "no_select"
    }
    const AboveSelector = (props: aboveSelectorProp) => {
        if(props.state === "base"){
        return(
                <div className='base select-base' id='select'>

                </div>
            )
        }
        if(props.state === "comfort"){
            return(
                <div className='comfort select-base' id='select'>
    
                </div>
            )
        }
        if(props.state === "prestige"){
            return(
                <div className='base select-base' id='select'>
    
                </div>
            )
        }
        return(
            <></>
        )
            
    }


    return (
        <div>
            <div className='selection-index'>
                <div onClick={() => {setSelected("base")}}> 
                <MainTile image={base} title='Базовый' desc='нищий' descWidth={"350px"} redirect='#select' />
                </div>
                <div onClick={() => {setSelected("comfort")}}>
                <MainTile image={average} title='Комфорт' desc='средне' descWidth={"350px"} redirect='#select'/>
                </div>
                <div onClick={() => {setSelected("prestige")}}>
                <MainTile image={expensive} title='Престиж' desc='круто' descWidth={"350px"} redirect='#select'/>
                </div>
            </div>
            <AboveSelector state={selected}/>
        </div>
  )
}
