import react from "react"
import {useState, useEffect} from "react"
import styled from 'styled-components';

const StopwatchMain = styled.div`

    display: flex;
    justify-content: center;
`

const Display = styled.div`
    width: fit-content;

    .stopwatch-name{
        margin-bottom: 0px;
        color: #bb2649;
    }

    .stopwatch-digit{
        margin-bottom: 0;
        color: #bb2649;
    }
`

const StopWatchCenter = styled.div`
    display:flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bs-gray-200);
    padding-bottom: 10px;
    padding-top: 10px;
`



export default function RemainCounter2() {

    const [cState, setCState] = useState<"setted" | "not_setted" | "ended">("not_setted");
    const [dayLeft, setDayLeft] = useState("");
    
    useEffect(() => {
        setCState("not_setted");
        setTimeout(() => {

            if (localStorage.getItem("date_end") !== null && localStorage.getItem("date_end") !== "null"){
                var timeNow = Date.now().toString();
                var timeEnd = Date.parse(localStorage.getItem("date_end") as string);
                var seconds_req = (timeEnd - (parseInt(timeNow))) / 1000;
                var days_left = Math.floor(seconds_req / 86400) + 1;
                if(days_left === 0){
                    setCState("ended");
                    return;
                }
                setDayLeft(days_left.toString());
                setCState("setted");
            }
        } , 100);   
    }, [])

    if(cState === "setted"){
        return (
            <>
                <StopWatchCenter>
                    <div className='title-fit'>
                        <h3 className='remaininfo'>До конца ремонта осталось: </h3>
                    </div>
                    <StopwatchMain>
                        <Display>
                            <h2 className='stopwatch-digit'>{dayLeft}</h2>
                            <p className='stopwatch-name'>Дней</p>
                        </Display>
                    </StopwatchMain>
                </StopWatchCenter>
            </>
        
        )
    }
    if(cState === "ended"){
        return(
            <>
                <StopWatchCenter>
                        <div className='title-fit'>
                            <h3 className='remaininfo'>Ваш ремонт почти закончен</h3>
                        </div>
                        <StopwatchMain>
                            <Display>
                                <h4 className='stopwatch-digit'>Скоро мы вас оповестим</h4>
                            </Display>
                        </StopwatchMain>
                    </StopWatchCenter>
            </>
        )
    }
    return(
        <>
            <StopWatchCenter>
                    <div className='title-fit'>
                        <h3 className='remaininfo'>Сроки вашего ремонта еще уточняются</h3>
                    </div>
                    <StopwatchMain>
                        <Display>
                            <h4 className='stopwatch-digit'>Скоро мы вас оповестим</h4>
                        </Display>
                    </StopwatchMain>
                </StopWatchCenter>
        </>
    )
}
