import React, {useEffect, useState} from 'react'
import NewsCard from '../../Components/NewsCard/NewsCard'
import styled from "styled-components"

const NewsSection = styled.section`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #efefefae;

`

export default function NewsPage() {

    const [news, setNews] = useState<Array<any>>([]);
    
    useEffect(() => {
        const fetchNews = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_news`;
            const res = await fetch(url, {
              method: "POST",
              headers: {
                "Authorization" : (localStorage.getItem("token") as string)
              }
            })
            res
              .json()
              .then(res => {
                console.log(res.data);
                setNews(res.data);
              })
          }
          fetchNews();
    }, [])


    return (
        <NewsSection>
            {news.map((item: any, index: number) => {
                return(
                    <NewsCard header={item.header} anotation={item.anotation} key={index} id={item.news_id} forward={true}/>
                )
            })}
        </NewsSection>
    )
}
