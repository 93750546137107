type tileProps = {
    image: any
    title: string,
    desc: string,
    redirect: string
}
var TileLineItems: Array<tileProps> = [   
    {
        title: "Выбор материалов",
        desc: "Форма выбора материалов",
        image: require("../../static/images/material_neuro_dark.webp"),
        redirect: "https://metrygroup.ru/selection"
    },{
        title: "Заполнить контактные данные",
        desc: "Форма обратной связи",
        image: require("../../static/images/lawyer_dark.webp"),
        redirect: "contact"
    },{
        title: "Запросить фотоотчет",
        desc: "Актуальная информация о ремонте",
        image: require("../../static/images/hotographer_dark.webp"),
        redirect: "photos"
    },{
        title: "Связаться с руководством",
        desc: "Получить контактные данные",
        image: require("../../static/images/builder_with_client_neuro_dark.webp"),
        redirect: "help"
    },{
        title: "Дополнительные услуги",
        desc: "Форма выбора дополнительных услуг",
        image: require("../../static/images/extra_dark.webp"),
        redirect: "extra"
    }
]

export {TileLineItems}
