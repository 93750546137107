import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "react-bootstrap"

const ExtraTileStyle = styled.div`
    background-color: #cdcaca;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
`

export default function ExtraTile(props: any){
    
    const [deleted, setDeleted] = useState(false);
    const Id = props.id;
    console.log(Id);
    const deleteHandler = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/del_addition`;
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify({
                id : Id 
            })
        })
        res
            .json()
            .then(data => {
                console.log(data);
                setDeleted(true);
            }) 
    } 

    if(!deleted){
        return(
            <ExtraTileStyle className='addition-tile' >
                <h4>{props.info}</h4>
                <Button variant='danger' onClick={deleteHandler}>Удалить</Button>
            </ExtraTileStyle>
        )
    }
    return(
        <></>
    )
}