import react, {useState, useEffect} from "react";
import ContactTemplate from "../../Components/HelperPageInfo/HelperInfo";


export default function ManagerPage(){

    const [manager, setManager] = useState<any>(null);
    useEffect(() => {
      
      const getData = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/get_manager_by_user_id`;
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Authorization" : (localStorage.getItem("token") as string)  
          },
          body : JSON.stringify({
            user_id: (localStorage.getItem("id") as string)
          })
        })
        res
          .json()
          .then(data => setManager(data.data));

      }
      getData();
    }, [])
    if(manager){
        return(
            <div className='helper-index'>
                <h2 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "var(--bs-gray-150)", margin: 0}}>Ваш менеджер</h2>
                <ContactTemplate phone={manager.phone} user_name={manager.user_name} email={manager.email} image={manager.avatar}/>
            </div>
        )
    }

    return(
      <div className='helper-index'>
        <h2 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "var(--bs-gray-150)", margin: 0}}>За вами еще не закреплен менеджер</h2>
        <h5 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "white", margin: 0}}>Скоро здесь появится контактная ифнормация</h5>
      </div>
    )
}