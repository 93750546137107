import React, {useEffect, useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Form } from 'react-bootstrap';

const CreateNewsSection = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 10px;
    flex-direction: column;
`
const ButtonSection = styled.section`
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    justify-content: end;
    width: 90%;
    
    button{
        margin: 5px;
    }
`
export default function AdminNewsEdit() {
    const navigate = useNavigate();
    const id  = useParams();
    const [pState, setPState] = useState<"editing"|"success"|"error"|"deleted">("editing");

    const [postBody, setPostBody] = useState(""); 
    const [header, setHeader] = useState("");
    const [anotation, setAnotation] = useState("");

    const [valid, setValid] = useState<boolean>(true);

    useEffect(() => {
        const getPostBody = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_news_by_id`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : (localStorage.getItem("token") as string)
                },
                body: JSON.stringify({
                    news_id: id.news_id
                })
            })
            res
                .json()
                .then(res => {
                    if(res.res === 0){
                        setAnotation(res.data.anotation);
                        setPostBody(res.data.content);
                        setHeader(res.data.header);
                    }else{
                        return;
                    }
                })
        } 
        getPostBody();
    }, [])

    const deleteNewsHandler = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/delete_news_by_id`;
        await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify({
                news_id: id.news_id
            })
        })
        setPState("deleted");
        setTimeout(() => {
            navigate("/news")
        }, 500)
    }

    const editNewsHandler = async () => {
        const sendData = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/edit_news_by_id`;
            
            const reqBody = {
                news_id: id.news_id,
                header: header,
                anotation: anotation,
                content: postBody
            }
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : (localStorage.getItem("token") as string)
                },
                body:  JSON.stringify(reqBody)
            })
            res
                .json()
                .then(res => {
                    if(res.res == 0){
                        setValid(true);
                        setPState("success");
                    }else{
                        setPState("error");
                    }
                })
        }
        if(postBody !== "" && header !== "" && anotation !== ""){
            sendData();
            return;
        }
        setValid(false);
        return;
    }

    const cancelChangesHandler = async () => {
        navigate("/news");
    }
    if(pState === "editing"){
        
        return (
          <>
              <CreateNewsSection >
                  <h2>Создание новости</h2>
                  <div style={{width: "90%"}}>
                      <Form>
                          <Form.Group>
                              <Form.Label>Заголовок новости</Form.Label>
                              <Form.Control value={header} onChange={(e) => {
                                  setHeader(e.target.value);
                              }} isInvalid = {header === "" ? true : false}></Form.Control>
                          </Form.Group>
                          <Form.Group>
                              <Form.Label>Аннотация</Form.Label>
                              <Form.Control value={anotation} onChange={(e) => {
                                  setAnotation(e.target.value);
                              }} isInvalid={anotation === "" ? true : false}></Form.Control>
                          </Form.Group>
                      </Form>
                      <hr />
                      <Editor value={postBody} onEditorChange={async (a: string) => {setPostBody(a);}}/>
                  </div>
              </CreateNewsSection>
              {valid === false ? <p style={{color : "red"}}>Какое-то поле не заполнено</p> : null}
              <ButtonSection>
                  <Button variant="warning" onClick={editNewsHandler}>Применить</Button>
                  <Button variant="primary" onClick={cancelChangesHandler}>Отмена</Button>
                  <Button variant="danger" onClick={deleteNewsHandler}>Удалить</Button>
              </ButtonSection>
          </>
        )
    }
    if(pState === "error"){
        return(
            <>
                <h2>Ошибка</h2>
                <p>Свяжитесь с отделом разработки</p>
            </>
        )
    }  
    if(pState === "success"){
        return(
            <>
                <h2>Новость добавлена успешно</h2>
                <p>Вы можете увидеть ее в списке новостей</p>
                <Link to={"/news"}>
                    <Button>На страницу новостей</Button>
                </Link>
            </>
        )
    }
    return(
        <></>
    )
         
}
