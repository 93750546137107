import React from 'react'
import { useState } from "react";
import styled from "styled-components"
import ReCAPTCHA from "react-google-recaptcha";
import {MdDoneOutline, MdOutlineCancel} from "react-icons/md"
import { useNavigate } from "react-router-dom";

const logo  = require("../../static/images/logo.png");

const AuthField = styled.div`
    @media only screen and (max-width: 800px){
        width: 100%;
        border-radius: 0;
        max-height: 60%;
        overflow-y: hidden;
    }
    overflow-y: hidden;
    border-radius: 10px;
    padding: 10px;
    width: 700px;
    height: fit-content;
    background-color: white;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;


    .image-block{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .auth-form{
        display: flex;
        height: 100%;
        width: 90%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-style{
            font-size: 1.3rem;
            padding-left: 20px;
            padding-right: 20px;
        }

    }

    .control-style{
        margin: 10px;
        width: 95%;
    }
`

const Logo = styled.img`
    width: 60%;
    min-width: 190px;
`



export default function AuthForm() {


    const [correct, setCorrect] = useState<boolean | null>(null);
    const [login, setLogin] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    const [badAuths, setBadAuths] = useState<number>(0);
    const [captcha, correctCaptcha] = useState(false);

    const navigate = useNavigate();

    const getUserData = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/get_contract_data`;
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify({
                user_id: localStorage.getItem("id") as string
            })
        })
        
        res
        .json()
        .then(res => {
            console.log(res);
            if(res.res === -1){
                return;
            }
            localStorage.setItem("date_start", res.data.date_start);
            localStorage.setItem("date_end", res.data.date_end);
            localStorage.setItem("money_total", res.data.money_total);
            localStorage.setItem("money_payed", res.data.money_payed);
            return;
        });
    
    }

    const AuthSubmit = async () => {
        const URL = `${process.env.REACT_APP_API_URL}login_post`;
        const userData:any = {
            username: login,
            password: password
        } 
        console.log(userData);
        //выполняется после успешной аутентификации 
       
        const responce = await fetch(URL, {
            method: "POST",
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : (localStorage.getItem("token") as string),
            }

        });
        
        const res = await responce.json();
        console.log(res);
        const status: 200 | 403 = res.auth_code
        if(status === 200){
            setBadAuths(0);
            setCorrect(true);
            setTimeout(() => {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("user_role", res.data.user_role);
                localStorage.setItem("id", res.data.user_id);
                //getUserData();
                navigate("/");   
            }, 500)
            
        }
        if(status === 403){
            setBadAuths(badAuths + 1);
            setCorrect(false);
            localStorage.clear();
        }
    }

    const CorrectNugget = () => {
        
        if(correct === true){
            return(
                <div className="alert alert-success margin-top-5" role="alert">
                    <MdDoneOutline></MdDoneOutline><h6 style={{display: "inline"}}> Успешно</h6>
                </div>
            )
        }
        if(correct === false){
            return(
                <div className="alert alert-danger margin-top-5" role="alert">
                    <MdOutlineCancel></MdOutlineCancel><h6 style={{display: "inline"}}> Доступ запрещен</h6>
                </div>
            )
        }
        return(
            <></>
        )
    }

    const oncaptchachange = (e: any) => {
        correctCaptcha(true);
    }

    return (
        <AuthField>
            <div className="image-block">
                <Logo src={logo} alt="logotype"/>
            </div>
            <CorrectNugget></CorrectNugget>
            <form className="auth-form" onSubmit={(event) => {event.preventDefault()}}>
                <input className="form-control control-style" type={"text"} placeholder="Пользователь" onChange={(event: any) => setLogin(event.target.value)}></input>
                <input className="form-control control-style" type={"password"} placeholder="Пароль" onChange={(event: any) => setPassword(event.target.value)}></input>
                <ReCAPTCHA sitekey='6LcborgkAAAAAPMykrOXFqqu26w--TOp5X_N3leq' onChange={oncaptchachange} onExpired={() => {correctCaptcha(false)}} size='normal'/>
                <button className="btn btn-danger btn-style" onClick={AuthSubmit} disabled={!captcha}>Войти</button>
            </form>
            
        </AuthField>
      )
}


  

