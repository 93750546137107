import React from "react"
import {GrUserWorker} from "react-icons/gr"
import { BiUser} from "react-icons/bi"
//import {TiContacts} from "react-icons/ti"
import {GrUserManager} from "react-icons/gr"
import {FiPercent} from 'react-icons/fi'
import {RxExit} from "react-icons/rx"



//const Contact = TiContacts;
const User = BiUser;
const Sale = FiPercent;
const Manager = GrUserManager;
const Worker = GrUserWorker;
const Logout = RxExit;

type headerDataType = {
    icon: any,
    text: string,
    link: string
}
const headerData: Array<headerDataType> = [
    {
        icon: <User></User>,
        text: "Профиль",
        link: "/profile"
    },{
        icon: <Manager></Manager>,
        text: "Ваш менеджер",
        link: "/manager"
    },{
        icon: <Worker></Worker>,
        text: "Ваш прораб",
        link: "/builder"
    }, {
        icon: <Sale></Sale>,
        text: "Новости / Акции",
        link: "/news"
    },
    //{
    //     icon: <Contact></Contact>,
    //     text: "Контакты",
    //     link: "/contact"
    // },
    {
        icon: <Logout></Logout>,
        text: "Выход",
        link: "/auth"
    }   
]

export {headerData}