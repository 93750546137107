import {saveAs} from "file-saver";
import { Packer } from "docx";
import { DocumentCreator } from "../WordControl/WordControl";
import {Options} from "./DocxDeclaration"


class DocumentProvider {
    
    async SavePodryadDogovor(clientName: string, personalData: any, typeOfDeal: Options){
        if(personalData !== null){ 
            const documentCreator = new DocumentCreator();
            console.log(personalData);
            const doc = documentCreator.createAgreement(personalData, typeOfDeal);
            
            var full_name = clientName.replace(" ", "_");
            
            
            Packer.toBlob(doc).then(blob => {
                console.log(blob);
                saveAs(blob, `${full_name}-Договор_Подряда.docx`);
                console.log("Document created successfully");
            });
        }
    }

}

export {DocumentProvider}