import React from 'react'
import {Routes, Route} from "react-router-dom"

import AuthPage from '../Pages/AuthPage/AuthPage';
import AdminMain from './Pages/AdminMain/AdminMain';
import AdminLayout from './Components/AdminLayout/AdminLayout';
import AdminExtra from './Pages/AdminExtra/AdminExtra';
import AdminCreateUser from './Pages/AdminCreateUser/AdminCreateUser';
import AdminUsers from './Pages/AdminUsers/AdminUsers';
import NotFoundPage from '../Pages/NotFoundPage/NotFoundPage';
import AdminCurrentUser from './Pages/AdminCurrentUser/AdminCurrentUser';
import AdminEditContactPage from './Pages/AdminEditContact/AdminEditContactPage';
import AdminAPIDock from './Pages/AdminAPIDock/AdminAPIDock';
import AdminAllEmployes from './Pages/AdminAllEmploye/AdminAllEmployePage';
import AdminAddExtraPage from './Pages/AdminAddExtra/AdminAddExtraPage';
import AdminNewsPage from './Pages/AdminNews/AdminNewsPage';
import AdminNewsCreate from './Pages/AdminNewsCreate/AdminNewsCreate';
import AdminNewsEdit from './Pages/AdminNewsEdit/NewsEdit';
import AdminDocument from './Pages/AdminAppDocumentPage/AdminDocument';

export default function AdminApp() {
  
  
    return (
        <>
            <Routes>
                <Route path="/auth">
                    <Route index element={<AuthPage/>}/>
                </Route>
                <Route path='/' element={<AdminLayout/>}>
                    <Route index element={<AdminMain/>}/>
                    <Route path='/admin-extra' element={<AdminExtra/>}/>
                    <Route path="/admin-extra/add-extra" element={<AdminAddExtraPage/>}></Route>
                    <Route path='/user-creation' element={<AdminCreateUser/>}/>
                    <Route path='/users' element={<AdminUsers/>}/>
                    <Route path="/user/:id" element={<AdminCurrentUser/>}/>
                    <Route path="/admin-contact-edit" element={<AdminEditContactPage/>}/>
                    <Route path='/api-dock' element={<AdminAPIDock/>}/>
                    <Route path='/employes' element={<AdminAllEmployes/>}/>
                    <Route path='/news' element={<AdminNewsPage/>}/>
                    <Route path='/news-create' element = {<AdminNewsCreate/>}/>
                    <Route path='/news-edit/:news_id' element = {<AdminNewsEdit/>}/>
                    <Route path='/documents/:user_id' element = {<AdminDocument/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Route>   
            </Routes>
      </>
    )
}
