import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import styled from "styled-components"
import ExtraTile from '../ExtraTile/ExtraTile';




export default function ExtraControl() {
  const [additions, setAdditions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/all_addition`;
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Authorization" : (localStorage.getItem("token") as string)
          }
        })

        res
          .json()
          .then(data => {setAdditions(data.data)})
    }
    fetchData();

  }, [])

  return (
    <div>
      {additions.map((item: any, index: number) => {
        return(
          <ExtraTile info={item.info} id={item.id}/>
        )
        
      })}
      <Link to={"add-extra"}>
        <Button style={{marginTop: "10px"}}>Добавить услугу</Button>
      </Link>
    </div>
  )
}
