import {GrUserWorker} from "react-icons/gr"
import { BiUser} from "react-icons/bi"
import {GrUserManager} from "react-icons/gr"
import {FiPercent} from 'react-icons/fi'
import {RxExit} from "react-icons/rx"



//const Contact = TiContacts;
const User = BiUser;
const Sale = FiPercent;
const Manager = GrUserManager;
const Worker = GrUserWorker;
const Logout = RxExit;

type headerDataType = {
    icon: any,
    text: string,
    link: string
}
const adminHeaderData: Array<headerDataType> = [
    {
        icon: <User></User>,
        text: "Профиль",
        link: "/profile"
    },{
        icon: <Manager></Manager>,
        text: "Пользователи",
        link: "/users"
    },{
        icon: <Worker></Worker>,
        text: "Чаты",
        link: "/builer"
    }, {
        icon: <Sale></Sale>,
        text: "Акции",
        link: "/news"
    },
    {
        icon: <Logout></Logout>,
        text: "Выход",
        link: "/auth"
    }   
]

export {adminHeaderData}