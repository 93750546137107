import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFoundWrapper = styled.div`

`


export default function NotFoundPage(){

    return(
        <NotFoundWrapper>
            <h1>Такой страницы не существует</h1>
            <p>В любой раздел личного кабинета можно попасть с главной страницы</p>
            <Link to={"/"}><button className="btn btn-danger">Домой</button></Link>
        </NotFoundWrapper>
    )
    
}