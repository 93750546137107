import React, {useState, useEffect} from 'react'
import ContactTemplate from '../../Components/HelperPageInfo/HelperInfo'

export default function HelpPage() {

  const [data, setData] = useState<any>();
  useEffect( () => {
    const url = `${process.env.REACT_APP_API_URL}index/get_helper_contact`;
    const getData = async () => {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization" : (localStorage.getItem("token") as string)
        }
      })
      res
        .json()
        .then((data) => {
          setData(data.data);
        });
    }
    getData();
    

  }, []);

  if(data){
    return (
      <div className='helper-index'>
          <h2 style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "var(--bs-gray-150)", margin: 0}}>Рководитель коммерческого блока</h2>
          <ContactTemplate phone={data.phone} user_name={data.user_name} role = {data.role} email={data.email} image={data.avatar} />
      </div>
    )
  }
  return(
    <div>загрузка</div>
  )
}
