import React from "react";
import { useState } from "react";
import styled from 'styled-components'
// import { headerData } from "./headerData";
import {BiMenu} from "react-icons/bi"
import { Link } from "react-router-dom";

const logo = require("../../static/images/logo.png");



const LogotypeSection = styled.div<{fitted: boolean}>`
    @media only screen and (max-width: 1070px){
        padding-top: ${({fitted}) => (fitted ? "0": "10px" ) };
        width: 100px;
    }
    
    @media only screen and (max-width: 331px){
        display:  ${({fitted}) => (fitted ? "none" : "block")};
            
        }

    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    
    img{
        @media only screen and (max-width: 331px){

            display:  ${({fitted}) => (fitted ? "none" : "block")};
            
        }
    }

`

const Nav = styled.div<{fitted: boolean}>`
    @media only screen and (max-width: 1070px){
        height: ${({fitted}) => (fitted ? "60px" : "fit-content")};
        display: ${({fitted}) => (fitted ? "" : "flex")};
        flex-direction: ${({fitted}) => (fitted ? "" : "column")};
        flex-wrap: nowrap;
        align-items: center;
        
        
    }
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--bs-gray-300);
    height: 100px;
    display: flex;
    justify-content: space-around;
    z-index: 100;
    box-shadow: 0px 0px 4px #262a3985;
`

const Centrer = styled.div<{fitted: any}>`
    @media only screen and (max-width: 1070px){
        height: ${({fitted}) => (fitted ? "60px" : "fit-content")};
        display: ${({fitted}) => (fitted ? "" : "flex")};
        flex-direction: ${({fitted}) => (fitted ? "" : "column")};
        flex-wrap: nowrap;
        align-items: center;
        min-width: none;
        
    }
    max-width: 1700px;
    display: flex;
    justify-content: space-between;
`


const NavFlex = styled.nav<{fitted: boolean}>`
    @media only screen and (max-width: 1070px){
        height: ${({fitted}) => (fitted ? "60px" : "400px")};
        display: ${({fitted}) => (fitted ? "" : "flex")};
        flex-direction: ${({fitted}) => (fitted ? "" : "column")};
        justify-content: space-around;
        /* width: ${({fitted}) => (fitted ? "100px" : "")} */
    }
    height: 100px;
    display: flex;
    width: fit-content;
    
`
const NavTile = styled.div<{fitted: boolean}>`
    @media only screen and (max-width: 1070px){
        visibility: ${({fitted}) => (fitted ? "hidden" : "visible")};
        width: ${({fitted}) => (fitted ? "0" : "fit-contenet")};
        height:  ${({fitted}) => (fitted ? "0" : "80%")};
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-right: 10px;
    height: 100%;
    margin-left: 20px;
    font-size: 1.5rem;

    .link-navigation{
        text-decoration: none;        
    }
`
const OpenButtonTile = styled.div`
    @media only screen and (max-width: 1070px){
        visibility: visible;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    visibility: hidden;
`

const OpenButton = styled(BiMenu)`
    @media only screen and (max-width: 1070px){
        font-size: 4rem;
        visibility: visible;
        opacity: 1;
    }
    visibility: hidden;
`

const NavLink = styled.a`
    text-decoration: none;
    color: black;
    white-space: normal;

    margin-left: 8px;
    :hover{
        text-decoration: underline;
    }
`





type headerDataType = {
    icon: any,
    text: string,
    link: string
}
export default function NavBar(props: {headerData: any}){

    const [hidden, setHidden] = useState<boolean>(true);

    const NullStorage = () => {
        localStorage.setItem("token", "");
        localStorage.setItem("user_role", "untermenshe");
        window.location.reload()
    }


    return(
        <Nav fitted={hidden} className="">
            <Centrer fitted={hidden}>
            <LogotypeSection fitted={hidden} onClick={() => {setHidden(true)}}>
                <Link to={"/"}><img src={logo} alt="logo" width={"99%"} /></Link>
            </LogotypeSection>
            <NavFlex fitted={hidden}>
                
                {props.headerData.map((item:headerDataType, index:number) => {
                    if(item.text === "Выход"){
                        return(
                            <NavTile fitted={hidden} key={index} onClick={() => {setHidden(true);}}>
                                {item.icon}
                                <Link to={item.link} className="link-navigation" onClick={NullStorage}><NavLink>{item.text}</NavLink></Link>
                            </NavTile>
                        )
                    }
                    return(
                        <NavTile fitted={hidden} key={index} onClick={() => {setHidden(true);}}>
                            {item.icon}
                            <Link to={item.link} className="link-navigation"><NavLink>{item.text}</NavLink></Link>
                        </NavTile>
                    )
                })}
                
                
                <OpenButtonTile>
                    <OpenButton onClick={() => {setHidden(!hidden)}}>      
                    </OpenButton>
                </OpenButtonTile>
            </NavFlex>
            
            </Centrer>
        </Nav>
    )
}