import React from 'react'
import { FormControl,
    FormHelperText,
    Input,
    InputLabel, 
    Checkbox
} from '@mui/material';
import { useState, useEffect } from 'react'
import AdminUserCard from '../AdminUserCard/AdminUserCard';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

import "./userstyle.css"

type userCardType = {
    user_id: number,
    phone: string,
    email: string,
    user_name: string,
    user_role: string,
    password: string, 
    avatar: any,
    avatar_min: any,
    enabled: string,
    manager_id: string,
    builder_id: string,
    personal_data_filled: string
}
export default function UsersField(props: any) {
    
    const [users, setUsers] = useState<Array<userCardType>>([]);
    const [error, setError] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(true);

    const [find, setFind] = useState<string>("");
    const [showDel, setShowDel] = useState<boolean>(false);
    const [type, setType] = useState<"tile" | "line">("line");
    const url = "http://91.245.210.84/metry_api/index.php/api_ng/index/get_users";
    var token = localStorage.getItem("token");


    useEffect(() => {
        
        const getUsers = async () => {
            if(token === null){token = ""}
            const ans = await fetch(url, {
                method: "GET",
                headers: {
                    "Authorization" : token,
                }
            })
            
            ans
            .json()
            .then((data) => {
                console.log(data);
                setUsers(data.data);
                setLoad(false)
            })
            .catch((error) => {setError(error)})    
        }
        getUsers();
    
    }, []);

    const Content = () => {
        return(
            <>
                {users.map((item: userCardType, index: number) => {
                    if(item.phone === null){
                        return(<></>)
                    }
                    if(item.user_role === "admin"){
                        return(<></>)
                    }
                    if((item.user_name.toUpperCase()).includes(find.toUpperCase())){  
                        if(showDel === true){
                            return(
                                <AdminUserCard user_id={item.user_id}  password={item.password} avatar={item.avatar} avatar_min={item.avatar_min} user_name={item.user_name} email={item.email} phone={item.phone} type={type} key={index} enable={item.enabled} manager_id={item.manager_id} builder_id={item.builder_id} pd={item.personal_data_filled}/>
                            )    
                        }
                        if(item.enabled === "1"){
                            return(
                                <AdminUserCard user_id={item.user_id}  password={item.password} avatar={item.avatar} avatar_min={item.avatar_min} user_name={item.user_name} email={item.email} phone={item.phone} type={type} key={index} enable={item.enabled} manager_id={item.manager_id} builder_id={item.builder_id} pd={item.personal_data_filled}/>
                            ) 
                        }
                        return(
                            <></>
                        )

                    }
                })}
            </>
        )
    }
    
    const BodyTiles = () => {
        return(
            <div className='user-section'>
                <Content/>
            </div>
        )
    }

    const BodyTable = () => {
        
        return(
            <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя пользователя</TableCell>
                            <TableCell align="right">Телефон</TableCell>
                            <TableCell align="right">Почта</TableCell>
                            <TableCell align="right">Персонал</TableCell>
                            <TableCell align="right">П.Д.</TableCell>
                            <TableCell align="center">Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Content/>
                    </TableBody>
                </Table>
            </TableContainer>
         
            </>
        )
    }
  
    if(load){
        return(
            <div>Загрузка</div>
        )
    }
    if(error){
        return(
            <div>Ошибка</div>
        )
    }
    return(
        <>
            <section className='find-section'>
            <FormControl style={{marginRight: "10px"}}>
                <InputLabel htmlFor="my-input">ФИО</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" value={find} onChange={(e)=>{setFind(e.target.value)}}/>
                <FormHelperText id="my-helper-text">Поиск по ФИО</FormHelperText>
                
            </FormControl>
            <ToggleButtonGroup
                orientation="horizontal"
                value={type}
                exclusive={true}
                >
                <ToggleButton value="tile" aria-label="list" onClick={(event, value) => setType("tile")} selected={type === "tile"}>
                    <AppsIcon />
                </ToggleButton>
                <ToggleButton value="line" aria-label="module" onClick={(event, value) => {setType("line")}} selected={type === "line"}>
                    <ListIcon />
                </ToggleButton>
            </ToggleButtonGroup>
            <FormControl style={{marginLeft: "20px"}}>
                <Checkbox checked={showDel} onClick={() => {setShowDel(!showDel)}}/>
            </FormControl>
            <FormControl>
                <p style={{marginTop: "8px"}}>Показать удаленных</p>
            </FormControl>
            </section>
            {type === "tile" ? <BodyTiles/> : null}
            {type === "line" ? <BodyTable/> : null}
        </>
    )

}