import React, {useState} from "react"
import styled from "styled-components"


type TileProp = {
    image: any,
    dWidth: string,
    hover: boolean
}
const Tile = styled.div<TileProp>`
    @media all and (max-width: 500px){
        width: 300px;
        background-size:  ${props => (props.hover ? "151%"  : "141%")};
        /* height: 100px; */
    }

    @media all and (max-width: 350px){
        width: 250px;
        background-size:  ${props => (props.hover ? "191%"  : "181%")};
        /* height: 100px; */
    }
        

    @media all and (max-width: 320px){
        width: 210px;
        background-size:  ${props => (props.hover ? "211%"  : "201%")};
    }

    @media all and (max-width: 220px){
        width: 150px;
        background-size:  ${props => (props.hover ? "291%"  : "282%")};
    }

    width: ${props => props.dWidth};
    height: 300px;
    border-radius: 10px;
    
    //background-color: black;
    background-position: 350px;
    background-color: #000000;
    background-image: url(${props => props.image});
    background-size: ${props => (props.hover ? "240%" : "230%")};
    
    //background-color: var(--bs-gray-900);
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
    
    transition: all 500ms;
    

    .bottom-place{
        height: 60%;
        width: 100%;
        border-radius: 10px;
        padding: 5px;
        text-decoration: none;
    }

    h5{
        transition: all 1000ms;
        color: ${props => (props.hover ? "#ffffff7f" : "white" )};
        text-shadow: 0px 0px 10px black;
    }

    p{
        transition: all 1000ms;
        color: ${props => (props.hover ?  "white" : "#ffffff7f")};
        text-shadow: 0px 0px 10px black;
    }


`
const Title = styled.h5`
    color: white;
    text-decoration: none;
    
`


const Desc = styled.p`
    color: #ffffff7f;
    text-decoration: none;
    
`

const TopPlace = styled.div`

    height: 40%;
    width: 100%;
    border-radius: 10px;

    @media all and (max-width: 500px){
        /* height: 10px; */
    }

    
`

type tileProps = {
    image: any,
    title: string,
    desc: string,
    redirect: string, 
    descWidth: string
}
export default function TileOutside(props: tileProps){
    
    const [hover, setHover] = useState<boolean>(false);

    return(
        <a href={props.redirect} style={{textDecoration: "none"}}>
        <Tile image={props.image} dWidth={props.descWidth} hover={hover} onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
            {/* <img src={builder} alt="not found" className="image" /> */}
            <TopPlace className="top-gap"></TopPlace>
            <div className="bottom-place">
                <Title>
                    {props.title}
                </Title>
                <Desc>
                    {props.desc}
                </Desc>
            </div>
        </Tile>
        </a>
    )
}