import React from "react";
import ContactForm from "../../Components/ContactFrom/ContactForm";


export default function ContactPage(){
    return(
        <div className="contact-index">
            <h2 style={{margin: "10px"}}>Заполнение контактной информации</h2>
            <ContactForm id={localStorage.getItem("id") as string} btntxt={"Отправить"} pdAgree={true}/>            
        </div>
    )

}
