import React, { SyntheticEvent } from 'react'
import {useState, useEffect} from "react"
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap'
import "./userCreate.css";

export default function UserForm() {
    //internal page state
    const [pState, setPState] = useState<"creating" | "error" | "created">("creating");

    //const [login, setLogin] = useState<string>("");         
    const [password, setPassword] = useState<string>("");   const [validPasswod, setValidPassword] = useState<boolean>(false);
    const [name, setName] = useState<string>("");           const [validName, setValidName] = useState<boolean>(false);
    const [mail, setMail] = useState<string>("");           const [validMail, setValidMail] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>("");         const [validPhone, setValidPhone] = useState<boolean>(false);
                                const [formValid, setFormValid] = useState<boolean>(true); 
    const finalValidation = async () => {
        if(validPasswod && validName && validMail && validPhone){
            setFormValid(true);
            return true;
        }
        setFormValid(false);
        sendLogAndPassToUser();
        return false;
    }

    const sendLogAndPassToUser = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/alert_user_new_account`; 
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem('token') as string)
            },
            body : JSON.stringify({
                user_email: mail,
                login: mail,
                password: password,
            })

        });
         res.json()
            .then((res) => {console.log(res)});
    }

    //из соображений логики вызывать finalValid имеет смысл только после установки валидности одного из полей формы
    // const loginHanler = async (e: React.ChangeEvent<HTMLInputElement>) => {        
    //     setLogin(e.target.value);
    //     if(e.target.value.length > 5){
    //         setLoginValid(true);
            // finalValidation();
    //         return;
    //     }
    //     setLoginValid(false);
    //     setFormValid(false);
    //     return;
    // }

    const passwordHandler = async (e:any) => {
        const password: string = e.target.value;
        setPassword(password);
        if(password.length >= 6){
            setValidPassword(true);
            // finalValidation();
            return;
        }
        setValidPassword(false);
        setFormValid(false);
        return;
    } 

    const userNameHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        var nameRegExp = /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/ 
        if(nameRegExp.test(e.target.value)){
            setValidName(true);
            // finalValidation();
            return;
        }
        setValidName(false);
        setFormValid(false);
        return;
    }

    const phoneValidation = async (e:React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
        const phoneRegex = new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$');
        if(phoneRegex.test(e.target.value) === true){
            setValidPhone(true);
            // finalValidation();
            return;
        }
        setValidPhone(false);
        setFormValid(false);
        return;
    }

    const mailHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setMail(e.target.value);
        const mailRegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        if(mailRegExp.test(e.target.value)){
            setValidMail(true);
            // finalValidation();
            return;
        }
        setValidMail(false);
        setFormValid(false);
        return;
    }

    const [selectedBuilder, setSelectedBuilder] = useState("0");
    const [selectedManager, setSelectedManager] = useState("0");

    const [areAdmin, setAreAdmin] = useState<boolean>(false);
    
    const [builders, setBuilders] = useState([]);
    const [managers, setManagers] = useState([]);
    useEffect(() => {
        
        const fetchManagers = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_all_manager`;
            const managers = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: (localStorage.getItem("token") as string)
                }
            })
            managers
                .json()
                .then(managers => managers.data)
                .then(managers => setManagers(managers));
        }
        
        const fetchBuilders = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_all_builder`;
            const builders = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: (localStorage.getItem("token") as string)
                }
            })
            builders
                .json()
                .then(builders => builders.data)
                .then(builders => setBuilders(builders))
        }
        fetchManagers();
        fetchBuilders();

    }, [])

    var token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_API_URL}index/add_user`

    const formAction = async () => {
       
        if(await finalValidation() === false){
            return false;
        }
        const reqBody = {
            password: password,
            user_name: name,
            email: mail,
            phone: phone,
            role: "user",
            manager_id: selectedManager,
            builder_id: selectedBuilder
        }
        
        

        const responce = await fetch(url, {
            method : "POST",
            body: JSON.stringify(reqBody),
            headers : {
                'Content-Type': 'application/json',
                "Authorization" : token as string,
            }
        })
        
        responce
            .json()
            .then((data) => {
                console.log(data);
                if(data.res === 0){
                    console.log("ok");
                    setPState('created');
                    return;
                }
                setPState('error');
                return;
            })
            .catch((err) => {console.log(err)})
        
            

    }

    if(pState == "creating"){
    return (
        <Form className='user-create'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Form.Group>
                            <Form.Label>ФИО</Form.Label>
                            <Form.Control isInvalid={!validName} placeholder='Имя пользователя' onChange={userNameHandler}></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Имя записывается в формате ФИО
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Электронная почта</Form.Label>
                            <Form.Control isInvalid={!validMail} placeholder='Электронная почта' onChange={mailHandler}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Прораб:</Form.Label>  
                            <select className="form-select form-select-sm app-form-select mb-2" disabled={areAdmin} onChange={(e)=> {setSelectedBuilder(e.target.value)}}> 
                            <option value="0">Указать позже</option>
                            {builders.map((item: any, index: number) => {
                                return(
                                    <option value={item.user_id} key={index}>{item.user_name}</option>
                                )
                            })}
                            </select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Group>
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control isInvalid={!validPasswod} placeholder='Пароль' onChange={passwordHandler}></Form.Control>
                            <Form.Control.Feedback type="invalid">
                               Пароль должен содержать не менее 6 символов
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Номер телефона</Form.Label>
                            <Form.Control isInvalid={!validPhone} type="tel" placeholder='+7 (932) 327-41-10' onChange={phoneValidation}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Менеджер:</Form.Label>  
                            <select className="form-select form-select-sm app-form-select mb-2" disabled={areAdmin} onChange={(e) => {setSelectedManager(e.target.value)}}>
                                <option value="0">Указать позже</option>
                                {managers.map((item: any, index: number) => {
                                    return(
                                        <option value={item.user_id} key={index}>{item.user_name}</option>
                                    )
                                })}
                            </select>
                        </Form.Group>
                    </div>
                </div> 
                {/* <Form.Group>
                    <div className='inline-input'>
                        <Form.Check className='mt-1' checked={areAdmin} onChange={() => {setAreAdmin(!areAdmin)}}></Form.Check>            
                        <Form.Label>Администратор</Form.Label>
                    </div>
                </Form.Group> */}
            </div>
            {formValid === false ? <p style={{color : "red", margin: "4px"}}>Проверьте корректность введенных данных</p> : <></>}
            <Button onClick={formAction} style={{marginBottom: "10px"}} disabled={false}>Создать</Button>
        </Form>
        )
    }
    if(pState === "error"){
        return(
            <div>
                <h2>Что-то пошло не так, попробуйте еще раз</h2>
                <Button color='danger' onClick={() => {setPState("creating")}}>К созданию пользователя</Button>
            </div>
        )
    }
    if(pState === "created"){
        return(
            <div>
                <h2>Пользователь успешно создан</h2>
                <Link to={"/users"}>
                    <Button variant='info' style={{marginRight: "10px"}}>Список пользователей</Button>
                </Link>
                <Link to={"/"}>
                    <Button color="warning" style={{marginLeft: "10px"}}>Главное меню</Button>
                </Link>
            </div>
        )
    }

    return(<></>)
}

