import React from "react"
import styled from "styled-components"

const EmployeIndex = styled.div`
    width: 100%;
    display: flex;
`

export default function EmployeTile(props : {name: string, role: string}){

    return(
        <EmployeIndex>
            <h3>{props.name}</h3>
            
            <h4>{props.role}</h4>
        </EmployeIndex>
    )
} 