import React from 'react'
import { useState, useEffect } from "react"
import { useLocation, useParams } from 'react-router-dom';
import DocuementCard from '../../Components/AdminDocuementsCard/DocuementCard';
import styled from 'styled-components';

const DocSection = styled.div`
    width:100%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
`

export default function AdminDocument() {
    const parametrs = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [name, setName] = useState("");
    const [docs, setDocs] = useState<any>(null);

    const [dopryad, setPodryad] = useState(false);
    const [check, setCheck] = useState(false);
    const [akt, setAkt] = useState(false);

    const [action, setAction] = useState("");

    useEffect(() => {
        const queryP = new URLSearchParams(location.search);
        
        setName(queryP.get("name") as string);
       

        const url = `${process.env.REACT_APP_WORD_COMPRESSOR}docks_status/${parametrs.user_id}`;
        const getDocs = async () => {
            const res = await fetch(url)
            res
                .json()
                .then((data) => {
                    setDocs(data);
                    console.log(data);
                })
        } 
        getDocs();

        console.log(`${process.env.REACT_APP_WORD_COMPRESSOR}documents/${action}`);
    }, [])

    console.log(parametrs)
    if(docs !== null){
        return (
            <>
                <h2>Документы пользователя - {name}</h2>
                <DocSection>
                    {docs.podryad === "1" ? <DocuementCard title='Договор подряда' desc='Договор подряда' name={name} id={parametrs.user_id}/> : <DocuementCard title='Договор подряда' desc='Договор подряда' disabled/>}
                    {docs.check === "1" ? <DocuementCard title='Счет' desc='Счет'/> : <DocuementCard title='Счет' desc='Счет' disabled/>}
                    {docs.akt === "1" ? <DocuementCard title='Акт' desc='Акт'/> : <DocuementCard title='Акт' desc='Акт' disabled/>}
                </DocSection>
            </>    
        )}
    return(<></>)
}
