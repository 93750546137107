import React, {useState, useEffect} from 'react'
import { Button } from 'react-bootstrap'
import AdminNewsControl from '../../Components/AdminNewsControl/AdminNewsControl'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

export default function AdminNewsPage() {

  const [news, setNews] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchNews = async () => {
      const url = `${process.env.REACT_APP_API_URL}index/get_news`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization" : (localStorage.getItem("token") as string)
        }
      })
      res
        .json()
        .then(res => {
          console.log(res.data);
          setNews(res.data);
        })
    }
    fetchNews();


  }, [])

  const TableContent = () => {
    if(news){
      return(
        <>
          {news.map((item: any, index: any) => {
              return(
                  
                <TableRow key={index} >
                  <TableCell>{item.header}</TableCell>
                  <TableCell align="center">{item.anotation}</TableCell>
                  <TableCell align="right">
                    <Link to={`/news-edit/${item.news_id}`}>
                      <Button>Подробнее</Button>
                    </Link>
                  </TableCell>
                </TableRow>

              )
          })}
        </>
      )
    }
    return(<></>)
  }

  return (
    <>
      <h2>Новости</h2>
      <Link to={"/news-create"}>
        <Button>Добавить новость</Button>
      </Link>
      <section style={{}}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell align="center">Анотация</TableCell>
                <TableCell align="right">Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableContent/>
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </>
  )
}
