import React from 'react'
import Paper from '@mui/material/Paper/Paper'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const NewsTile = styled(Paper)`
    max-width: 1120px;
    width: 80%;
    height: fit-content;
    min-height: 150px;
    
    margin: 10px;
    text-align: left;

    display: flex;
    flex-direction: column;

    h3{
        margin: 10px;
        text-indent: 10px;
    }

    .anotation{
        margin: 10px;
        text-indent: 20px;
    }

    @media all and (max-width: 500px){
        width: 97%;
    }

`

const NewsBody = styled.section`

    h3{
        margin: 10px;
        text-indent: 10px;
    }

    p{
        margin: 10px;
        text-indent: 20px;
    }
`

const ButtonMore = styled.p`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 30px;
    transition: 1000ms;
    color: #555555b8;
    cursor: pointer;
    font-size: large;

    :hover{
        margin-right: 20px;
        color: #555555;
    }


`
const ButtonLess = styled.p`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    transition: 1000ms;
    color: #555555b8;
    cursor: pointer;    
    font-size: large;

    :hover{
        margin-left: 20px;
        color: #555555;
    }

`

const ButtonMoreContainer = styled.div`
    width: 100%;
    height: fit-content;
    text-align: right;
    justify-self: flex-end;
`

const ButtonBackContainer = styled.div`
    width: 100%;
    height: fit-content;
    text-align: left;
    
`

export default function NewsCard(props: any) {
    const navigate = useNavigate();
    const goToNews = () => {
        if(props.forward){
            navigate(`/news/${props.id}`)
        }else{
            navigate(`/news`)
        }
    }

  return (
    <>
        <NewsTile elevation={1} onClick={goToNews}>
            <h3>{props.header}</h3>
            <p className='anotation'>{props.anotation}</p>
            {props.content != null ? 
            <NewsBody dangerouslySetInnerHTML={{ __html: props.content }}></NewsBody>
            : null}
            {props.forward === true ? 
            <ButtonMoreContainer>
                <ButtonMore className='button-more'>{`Подробнее 〉`} </ButtonMore>
            </ButtonMoreContainer> :
            <ButtonBackContainer>
                <ButtonLess className='button-more'>{`〈 Назад `} </ButtonLess>
            </ButtonBackContainer> }
        </NewsTile>
    </>
  )
}
