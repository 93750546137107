import { AlignmentType, BorderStyle, convertInchesToTwip, INumberingOptions, IParagraphStyleOptions, ISectionPropertiesOptions, IStylesOptions, LevelFormat, LevelSuffix, SectionType } from "docx"

class DockConfig {



    public defalutTextStyle: IParagraphStyleOptions = {
        id: "default",
        run: {
            size: "11pt"
        }
    }


    public testStyle: IParagraphStyleOptions = {
        id: "test-style",
        
    }

    public defaultTableStyle = {

    }

    public sectionPropertiesDefault: ISectionPropertiesOptions  = {
        
        titlePage: true,
        type: SectionType.CONTINUOUS,
        page: {
            margin: {
                top: convertInchesToTwip(0.59),
                right: convertInchesToTwip(0.49),
                bottom: convertInchesToTwip(0.3),
                left: convertInchesToTwip(0.79),
            }
        }
        

    }

    public sectionMargin = {
        page: {
            margin: {
                top: 0.59,
                right: 49,
                bottom: 0.3,
                left: 79,
            },
        },
    }

    public dick = {
        page: {
            margin: {
                top: 59,
                right: 49,
                bottom: 3,
                left: 79,
            },
        },
    }


    public baseStyles: IStylesOptions = {
        paragraphStyles: [
            {
                id: "default",
                run: {
                    size: "11pt"
                    
                }

            }, 
            {
                id: "text-block-default",
                run : {

                }
            },
            {
                id: "footer-default",
                run: {
                    size: "11.5pt"
                }
            }
        ]
    }

    // offical documentation suck 
    // Пишу Документацию прямо здесь, тк чтоб разобраться в офицальной надо пройти пару кругов ада
    // кароче, когда стайлишь уровни вложености в документ надо понимать следующее
    // 1. Уровни вкладываются друг в друга до 8 (восьмого)
    // 2. Ворд умный, и сам поймет как расставить нумерацию, тебе надо лишь сказать ему, как это сделать
    //  Для начала ты конфигурируешь стайлы для всех нумериков в документе
    // все свойства вне поля style отвечают исключительно за стайл самого нумератора, а свойства внутри стайла, за элементы, к которым нумерик будет применен
    public baseNumberingStyles: INumberingOptions =  {
        config: [
          {
            reference: "default-dash",
              levels: [
                {
                  level: 0,
                  format: LevelFormat.UPPER_LETTER,
                  text: "\u002D",
                  alignment: AlignmentType.CENTER,
                  style: {
                      paragraph: {
                          alignment: AlignmentType.BOTH
                      },
                  },
                },
            ]
          }, 
          {
            reference: "default-numering",
            levels: [
                {
                    level: 0,
                    format: LevelFormat.DECIMAL,
                    text: "%1.",
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            alignment: AlignmentType.CENTER,
                        },
                    }
                },
                {
                    level: 1,
                    format: LevelFormat.DECIMAL,
                    text: "%1.%2",
                    alignment: AlignmentType.CENTER,
                    suffix: LevelSuffix.TAB,
                    style: {
                        paragraph: {
                            alignment: AlignmentType.BOTH
                        }
                    },
                }
            ],
          }
        ]
    }

}

export {DockConfig}