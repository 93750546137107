import react from "react"
import Button from "@mui/material/Button"
import {useNavigate} from "react-router-dom"
import { useEffect } from "react";

export default function PhotosPage(){
    const redirect = useNavigate();

    const sendData = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/send_mail_handler`;
        const body = {
            owner_id: localStorage.getItem("id") as string,
            mail_type: "photos"
        }
        await fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                Authorization: localStorage.getItem("token") as string
            }
        })
        redirect("/");
        
    }
    
    useEffect(() => {
        //sendData();
    }, []);

    return(
        <section className="photos page">
            <h2>Подтвердите ваш запрос</h2>
            <p>Для отправки запроса нажмите кнопку ниже, вас вернет на главную страницу</p>
            <Button variant="contained" color={"error"} onClick={sendData}>Подтвердить</Button>
        </section>
    )

}