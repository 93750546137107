import React from 'react'
import UsersField from '../../Components/UsersField/UsersField'


export default function AdminUsers() {
  return (
    <div style={{width: "100%", height: "fit-content"}}>
        <UsersField/>
    </div>
  )
}
