import React from 'react'
import styled from "styled-components"
import {Paper} from "@mui/material"
import {RiFileWord2Fill} from "react-icons/ri";
import { Button } from 'react-bootstrap';

const DockWrap = styled(Paper)`
    width: 90%;
    display: grid;
    grid-template-columns: 20% 80%;
    padding-top: 5px;
    padding-bottom: 10px;
    margin: 10px;

    .wordLogo-section{
        height: auto;
        display: flex;
        justify-content:center;
        align-items: center;
    }
    .wordLogo{
        width: 100%;
    }

    .names{
        width: 79%;
        height: fit-content;
    }
`

type DocuementCardProps = {
    title: string,
    desc?: string,
    id?: string,
    name?: string,
    disabled?: boolean | undefined
}
export default function DocuementCard(props: DocuementCardProps) {
    if(props.disabled){
        return(
            <DockWrap elevation={1}>
                <div className='wordLogo-section'>
                    <h1 className='wordLogo'><RiFileWord2Fill/></h1>
                </div>
                <div className='names'>
                    <h3 className='title'>{props.title}</h3>
                    <p className='desc'>{props.desc}</p>
                    <Button disabled>Загрузить</Button>
                </div>
            </DockWrap>
        )
    }
  
    return (
    <DockWrap elevation={5}>
        <div className='wordLogo-section'>
            <h1 className='wordLogo'><RiFileWord2Fill/></h1>
        </div>
        <div className='names'>
            <h3 className='title'>{props.title}</h3>
            <p className='desc'>{props.desc}</p>
            <a href={`${process.env.REACT_APP_WORD_COMPRESSOR}documents/${props.id}_${props.name?.split(" ").join("_")}_${props.title.split(" ").join("_")}.docx`}><Button>Загрузить</Button></a>
        </div>
        
    </DockWrap>
  )
}
