import React from 'react'
import Footer from '../../../Components/Footer/Footer'
import NavBar from '../../../Components/NavBar/NavBar'
import { Outlet } from "react-router-dom"
import { adminHeaderData } from './AdminHeaderData'

export default function AdminLayout() {
  return (
    <>
        <NavBar headerData={adminHeaderData}/>
            <div className="App">
                <Outlet/>
            </div>    
        <Footer/>
    </>
  )
}
