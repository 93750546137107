import React from 'react'
import styled from 'styled-components'
import ExtraControl from '../../Components/ExtraControl/ExtraControl'


const AdminExtraIndex = styled.div`
  width: 100%;
  height: fit-content;
`

export default function AdminExtra() {
  return (
    <AdminExtraIndex>
      <ExtraControl />
    </AdminExtraIndex>
  )
}
