import * as fs from "fs";
import {
    AlignmentType,
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    ImageRun,
    TextWrappingSide,
    TextWrappingType,
    Tab,
    Table,
    TableRow,
    TableCell,
    ITableRowOptions,
    WidthType,
    Footer,
    BorderStyle,
    ITableWidthProperties,
    convertInchesToTwip,
    convertMillimetersToTwip,
    Header
  } from "docx";

import { imageBase64Data, headerImage } from "./Images";

import { ITableCellMarginOptions } from "docx/build/file/table/table-properties/table-cell-margin";



// Господи боже, по тому как работать с этой библиотекой очень не хватает нормальной инструкции
// Первое правило - если элемент это нумерик - его стайлы конфигурируются в поле style самого уровня вложености
// если эти слова вам ни о чем не сказали позвонить - 89323274110 - себе дороже
class DockModules {

    ////<<<INTERNAL STYLE>>>
    private footerBorderDefault = {
      top: {
        style: BorderStyle.DASH_SMALL_GAP
      },
      bottom: {
        style: BorderStyle.DASH_SMALL_GAP
      },
      right: {
        style: BorderStyle.DASH_SMALL_GAP
      },
      left: {
        style: BorderStyle.DASH_SMALL_GAP
      }
      
    }
    
    private footerDefaultWidth: ITableWidthProperties = {
      size: 30,
      type: WidthType.PERCENTAGE,
    }
    
    private footerDefaultMargin: ITableCellMarginOptions = {
      top: convertMillimetersToTwip(1),
      bottom: convertMillimetersToTwip(2)
    }
    
    ///<<<END INTERNAL STYLE>>>
    
    private headerImage = headerImage;

    public createBlankParagraph(){
      return new Paragraph({})
    }
    
    public createCityAndData(day: number, mounth: number, year: number, city: string): Paragraph{
        
      
      return new Paragraph({
            children: [
                new TextRun(`${city}\t\t\t\t\t\t\t\t\t\t\t«${day}» ${mounth} ${year} г.`)
            ],
            style: "default"
        })
    } 

    public createText(text: string){
        return new Paragraph({
            children: [
                new TextRun(text)
            ],
            style: "default"
        })
    }
    
    public createHeaderLeft(text: string): Paragraph{
        return new Paragraph({
          children: [
              new TextRun({
                  text: text,
                  color: "000000",
                  bold: true
              }), 
              new TextRun(""),
          ],
          heading: HeadingLevel.HEADING_3,
          alignment: AlignmentType.LEFT,
          style: "default"
    })
    }

    public createHeaderCenter(text: string): Paragraph {
        return new Paragraph({
            children: [
                new TextRun({
                    text: text,
                    color: "000000",
                    bold: true
                }), 
                new TextRun(""),
            ],
            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,
            style: "default"
        })
    }

    public createHeaderL0(text: string): Paragraph{
      return new Paragraph({
        children: [
            // new Paragraph({}),
            new TextRun({
                text: text,
                color: "000000",
                bold: true,
                italics: false,
            }), 
            // new Paragraph({}),
        ],
        heading: HeadingLevel.HEADING_3,
        numbering: {
          reference: "default-numering",
          level: 0
        },
        style: "default"
    })
    }

    public createTextL1(text: string): Paragraph {
      return new Paragraph({
          children: [
            new TextRun({
              text: text,
              
            })
          ],
          numbering: {
            reference: "default-numering",
            level: 1
          },
          style: "default",
      })
    } 
    
    public createBulletL1(text:string){
      return new Paragraph({
        numbering: {
          reference: "default-dash",
          level: 0,
        },
        text: text,
        style: "default"
      })
    }




    public createDockHeaderImage(): Paragraph {
        return new Paragraph({
        children: [
            new ImageRun({
                data: Uint8Array.from(atob(this.headerImage), c =>
                  c.charCodeAt(0)
                ),
                transformation: {
                  width: 220,
                  height: 100
                }
              })
        ]
        })
    };

    public createTableBuildingObject(personalData: any): Table{
      const rowsNames = [
        ["Город", personalData.id],
        ["Жилой район / комплекс", personalData.jk],
        ["ГП", personalData.living_address],
        ["Улица", personalData.object_address],
        ["№ дома", personalData.object_address],
        ["Подъезд", personalData.object_address],
        ["Секция", personalData.object_address],
        ["Этаж", personalData.object_address],
        ["№ квартиры", personalData.object_address],
        ["Площадь квартиры", personalData.object_area],
        ["Количество комнат", personalData.rooms],
        ["Количество СУ", personalData.toilets],
        ["Площадь ЖЗ", personalData.object_area],
        ["Площадь СУ", 10],
      ]

      var ObjectRows: Array<TableRow> = []
      rowsNames.forEach((item: Array<string>) => {
        ObjectRows.push(
          new TableRow({
            children: [
              new TableCell({
                children: [new Paragraph({
                  text: item[0],
                  style: "default"
                })],
                width: {
                  size: 30,
                  type: WidthType.PERCENTAGE,
                },
                
              }),
              new TableCell({
                children: [new Paragraph({
                  text: item[1],
                  style: "default"
                })],
                width: {
                  size: 30,
                  type: WidthType.PERCENTAGE,
                },
              })
            ]
        }),
        );
      })

      return new Table({
        rows: ObjectRows,
        style: "default",
        alignment: AlignmentType.CENTER,
      })
    } 

    public defaultFooter(): Footer{
      return new Footer({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                        new Paragraph({
                          text: "___________________/Андреев А.Е./",
                          style: "footer-default"
                        })
                    ],
                    width: this.footerDefaultWidth,
                    borders: this.footerBorderDefault,
                    margins: this.footerDefaultMargin,
                    
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text:  "___________________/____________/",
                        style: "footer-default"
                      })
                    ],
                    width: this.footerDefaultWidth,
                    borders: this.footerBorderDefault,
                    margins: this.footerDefaultMargin
                  }),
                ]
              
              
              })
            ],
          }) 
        ],
        
      });
    }

    public defaultHeader(): Header{
      return new Header({
        children: [
          new Paragraph({
            children: [
              new ImageRun({
                data: Uint8Array.from(atob(this.headerImage), c =>
                  c.charCodeAt(0)
                ),
                transformation: {
                  width: 234,
                  height: 111
                }
              })
            ],
        }),
        ]
      }) 
    } 


    public createContactInfo(
        phoneNumber: string,
        profileUrl: string,
        email: string
      ): Paragraph {
        return new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun(
              `Mobile: ${phoneNumber} | LinkedIn: ${profileUrl} | Email: ${email}`
            ),
            new TextRun({
              text: "Address: 58 Elm Avenue, Kent ME4 6ER, UK",
              break: 1
            })
          ]
        });
      }
    
      public createHeading(text: string): Paragraph {
        return new Paragraph({
          text: text,
          heading: HeadingLevel.HEADING_1,
          thematicBreak: true
        });
      }
    
      public createSubHeading(text: string): Paragraph {
        return new Paragraph({
          text: text,
          heading: HeadingLevel.HEADING_2
        });
      }
    
      public createInstitutionHeader(
        institutionName: string,
        dateText: string
      ): Paragraph {
        return new Paragraph({
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position: TabStopPosition.MAX
            }
          ],
          children: [
            new TextRun({
              text: institutionName,
              bold: true
            }),
            new TextRun({
              text: `\t${dateText}`,
              bold: true
            })
          ]
        });
      }
    
      public createRoleText(roleText: string): Paragraph {
        return new Paragraph({
          children: [
            new TextRun({
              text: roleText,
              italics: true
            })
          ]
        });
      }
    
      
    
      // tslint:disable-next-line:no-any
      public createSkillList(skills: any[]): Paragraph {
        return new Paragraph({
          children: [new TextRun(skills.map(skill => skill.name).join(", ") + ".")]
        });
      }
    
      // tslint:disable-next-line:no-any
      public createAchivementsList(achivements: any[]): Paragraph[] {
        return achivements.map(
          achievement =>
            new Paragraph({
              text: achievement.name,
              bullet: {
                level: 0
              }
            })
        );
      }
    
      public createInterests(interests: string): Paragraph {
        return new Paragraph({
          children: [new TextRun(interests)]
        });
      }
    
      public splitParagraphIntoBullets(text: string): string[] {
        return text.split("\n\n");
      }
    
      // tslint:disable-next-line:no-any
      public createPositionDateText(
        startDate: any,
        endDate: any,
        isCurrent: boolean
      ): string {
        const startDateText =
          this.getMonthFromInt(startDate.month) + ". " + startDate.year;
        const endDateText = isCurrent
          ? "Present"
          : `${this.getMonthFromInt(endDate.month)}. ${endDate.year}`;
    
        return `${startDateText} - ${endDateText}`;
      }
    
      public getMonthFromInt(value: number): string {
        switch (value) {
          case 1:
            return "Jan";
          case 2:
            return "Feb";
          case 3:
            return "Mar";
          case 4:
            return "Apr";
          case 5:
            return "May";
          case 6:
            return "Jun";
          case 7:
            return "Jul";
          case 8:
            return "Aug";
          case 9:
            return "Sept";
          case 10:
            return "Oct";
          case 11:
            return "Nov";
          case 12:
            return "Dec";
          default:
            return "N/A";
        }
      }
}

export {DockModules}