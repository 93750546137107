import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {Checkbox, Button} from "@mui/material"
import { Form } from 'react-bootstrap'
import "./contact.css";

export default function ContactForm(props: any){
    const redirect = useNavigate();
    //internal page state
    const [pState, setPState] = useState<"editing" | "done" | "error">("editing")

    const [mail, setMail] = useState("");                   const [validMail, setValidMail] = useState<boolean>(false);
    const [name, setName] = useState("");                   const [validName, setValidName] = useState<boolean>(false);
    const [bDay, setBDay] = useState("");                   const [validBDay, setValidBDay] = useState<boolean>(false); 
    const [passport, setPassport] = useState("");           const [validPassport, setValidPassport] = useState<boolean>(false);
    const [whosGet, setWhosGet] = useState("");             const [validWhosGet, setValidWhosGet] = useState<boolean>(false);
    const [dateGet, setDateGet] = useState("");             const [validDateGet, setValidDateGet] = useState<boolean>(false);
    const [code, setCode] = useState("");                   const [validCode, setValidCode] = useState<boolean>(false);
    const [address, setAddress] = useState("");             const [validAddress, setValidAddress] = useState<boolean>(false);
    const [livingAddress, setLivingAddress] = useState(""); const [validLivingAddress, setValidLivingAddress] = useState<boolean>(false);

    const [objectAddress, setObjectAddress] = useState(""); const [validObjectAddress, setValidObjectAddress] = useState<boolean>(false);
    const [JK, setJK]  = useState("Не выбрано");            const [validJK, setValidJK] = useState<boolean>(false);
    const [area, setArea] = useState("");                   const [validArea, setValidArea] = useState<boolean>(false); 
    const [rooms, setRooms] = useState("Не выбрано");       const [validRooms, setValidRooms] = useState<boolean>(false);//0 КОМНАТ = СТУДИЯ       
    const [toilets, setToilets] = useState("Не выбрано");   const [validToilets, setValidToilets] = useState<boolean>(false);
                                        const [formValid, setFormValid] = useState<boolean>(false);

    const [agree, setAgree] = useState<boolean>(false);

    const [personalDataCorrect, setPersonalDataCorrect] = useState<boolean>(true);
    const [showAgreeRequired, setShowAgreeRequired] = useState<boolean>(false);

    //CHANGE HANDLERS
    const finalValidation = () => {
        {
            // console.log("---------------------")
            // console.log("name", validName)
            // console.log("bday",validBDay)
            // console.log("mail",validMail)
            // console.log("passport",validPassport)
            // console.log("whosget",validWhosGet)
            // console.log("dateget",validDateGet)
            // console.log("code",validCode)
            // console.log("address",validAddress)
            // console.log("living address",validLivingAddress)
            // console.log("object address",validObjectAddress)
            // console.log("area",validArea)
            // console.log("agree", agree)
        }
        if(validMail && validName && validBDay && validPassport && validWhosGet && validDateGet && validCode && validAddress && validLivingAddress && validObjectAddress && validArea && validJK && validRooms && validToilets && agree)
        {
            setFormValid(true);
            return true;
        }
        setFormValid(false); 
        return false;
    }

    const mailHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setMail(e.target.value);
        const mailRegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        if(mailRegExp.test(e.target.value)){
            setValidMail(true);
        }else{
            setValidMail(false);
        }
        // finalValidation();

    }

    const nameHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        var nameRegExp = /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/ 
        if(nameRegExp.test(e.target.value)){
            setValidName(true);
        }else{
            setValidName(false);
        }
        // finalValidation();
    }

    const bdayHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const day = e.target.value;
        setBDay(day);
        if(day != ""){
            setValidBDay(true);
        }else{
            setValidBDay(false);
        }
        // finalValidation();
        
    }

    const passportHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const p: string = e.target.value
        if(p.length <= 10){
            const valid: string  = "0123456789";
            if((valid.includes(p[p.length - 1]) || p.length === 0)){ //символ валидный, если он содержится в строке valid или длина тела ивента равна нулю
                setPassport(p);
                if(p.length === 10){
                    setValidPassport(true);
                }else{
                    setValidPassport(false);
                }
                // finalValidation();
            }
            
        }   
    }
    
    const whosgetHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        setWhosGet(v);
        console.log(v.length);
        if(v.length > 0){
            setValidWhosGet(true);
        }else{
            setValidWhosGet(false);
        }
        console.log("valid whos get ", validWhosGet);
        // finalValidation();
        return;
    }

    const dateGetHandler = async (e: React.ChangeEvent<HTMLInputElement>)  => {
        const day = e.target.value;
        setDateGet(day);
        if(day != ""){
            setValidDateGet(true);
            // finalValidation();
            return;
        }
        setValidDateGet(false);
        // finalValidation();
        return;
    }

    const codeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const pcode: string = e.target.value
        if(pcode.length <= 6){   
            const valid: string  = "0123456789";
            if((valid.includes(pcode[pcode.length - 1]) || pcode.length === 0) ){
                setCode(pcode);
            }
            if(pcode.length === 6){
                console.log(pcode)
                setValidCode(true);
                // finalValidation();
                return;
            }
            setValidCode(false);
            // finalValidation();
            return;
        }
    
    }

    const addressHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const addr = e.target.value;
        setAddress(addr);
        if(addr != ""){
            setValidAddress(true);
            // finalValidation();
            return;
        }
        setValidAddress(false);
        // finalValidation();
        return;
    }

    const livingHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const addr = e.target.value;
        setLivingAddress(addr);
        if(addr != ""){
            setValidLivingAddress(true);
            // finalValidation();
            return;
        }
        setValidLivingAddress(false);
        // finalValidation();
        return;
    }

    const objectHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const addr = e.target.value;
        setObjectAddress(addr);
        if(addr != ""){
            setValidObjectAddress(true);
            // finalValidation();
            return;
        }
        setValidObjectAddress(false);
        // finalValidation();
        return;
    }

    const areaHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const S = e.target.value;
        if(S.length <= 4){
            const valid: string  = "0123456789";
            if((valid.includes(S[S.length - 1]) || S.length === 0)){
                setArea(S);
            }
            if(S.length <= 4 && S.length != 0){
                setValidArea(true);
                // finalValidation();
                return;
            }
            setValidArea(false);
            // finalValidation();
            return;
        }   
    }


    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_personal_data`;
            const body = {
                user_id: props.id
            }
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization : localStorage.getItem("token") as string
                },
                body: JSON.stringify(body)
            })
            res
            .json()
            .then(data => {
                if(data.res === 1){
                    throw new DOMException();
                }
                if(data.res === 0){
                    return data.data;
                }
                else{
                    throw new DOMException();
                }
            })
            .then(data => {
                setMail(data.mail);
                setName(data.full_name);
                setBDay(data.birth_date);
                setPassport(data.passport);
                setWhosGet(data.whos_get);
                setDateGet(data.pasport_date);
                setCode(data.code_podr);
                setAddress(data.reg_address);
                setLivingAddress(data.living_address);
                setJK(data.jk);
                setObjectAddress(data.object_address);
                setArea(data.object_area);
                setToilets(data.toilets);
                if(data.rooms === "Студия"){
                    setRooms("Студия");
                }else{
                    setRooms(data.rooms + " комнатная");
                }
                
            })
            .then(() => {
                setValidMail(true);
                setValidName(true);
                setValidBDay(true);
                setValidPassport(true);
                setValidWhosGet(true);
                setValidDateGet(true);
                setValidCode(true);
                setValidAddress(true);
                setValidLivingAddress(true);
                setValidObjectAddress(true);
                setValidArea(true);
                setFormValid(true);
                setValidJK(true);
                setValidRooms(true);
                setValidToilets(true);
            })
            .catch()
        }
        fetchData();
    }, [])

    
    //======================================


    const sendDataHandler = async () => {
        if(!agree && props.pdAgree){
            setShowAgreeRequired(true);
            return;
        }

        if(await finalValidation() !== true){
            setPersonalDataCorrect(false);
            return;
        }  

        var room_number = rooms.split(" ")[0]

        const body = {
            mail: mail, 
            full_name: name,
            birth_date: bDay,
            passport: passport, 
            whos_get: whosGet,
            pasport_date: dateGet,
            code_podr: code,
            reg_address: address,
            living_address: livingAddress,
            object_address: objectAddress,
            jk: JK,
            object_area: area,
            rooms: room_number,
            toilets : toilets,
            owner_id: props.id
        }

        console.log(body);

        if(agree){
            const url = `${process.env.REACT_APP_API_URL}index/set_personal_data`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : localStorage.getItem("token") as string,
                },
                body: JSON.stringify(body)
            })
            res
                .json()
                .then((data) => {
                     console.log(data);
                     setPState("done")
                }) // dont forget change to success
                .catch((data) => {
                    console.error('err');
                    console.log(data);
                    setPState("error");
                })
            
        }
    }

    const errorButtonHandler = async () => {
        setPState("editing");
    }

    const successButtonHandler = async () => {
        redirect("/");
    }

    //extra component ===============================
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const PdModal = () => {
        
        return(
            <>
                <Modal show={show}  onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Обработка персональных данных</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Предоставляя свои персональные данные Пользователь даёт согласие на
                        обработку, хранение и использование своих персональных данных на
                        основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в
                        следующих целях:</p> <ul><li>Осуществление клиентской поддержки</li> <li>Получения Пользователем информации о маркетинговых событиях</li> <li>Проведения аудита и прочих внутренних исследований с целью повышения
                            качества предоставляемых услуг.
                        </li></ul> <p>Под персональными данными подразумевается любая информация личного
                        характера, позволяющая установить личность Пользователя/Покупателя такая
                        как:</p> <ul><li>Фамилия, Имя, Отчество</li> <li>Контактный телефон</li> <li>Адрес электронной почты</li></ul> <p>Персональные данные Пользователей хранятся исключительно на электронных
                        носителях и обрабатываются с использованием автоматизированных систем,
                        за исключением случаев, когда неавтоматизированная обработка
                        персональных данных необходима в связи с исполнением требований
                        законодательства.</p> <p>Компания обязуется не передавать полученные персональные данные третьим
                        лицам, за исключением следующих случаев:</p> <ul><li>По запросам уполномоченных органов государственной власти РФ только
                            по основаниям и в порядке, установленным законодательством РФ
                        </li> <li>Стратегическим партнерам, которые работают с Компанией для
                            предоставления продуктов и услуг, или тем из них, которые помогают
                            Компании реализовывать продукты и услуги потребителям. Мы
                            предоставляем третьим лицам минимальный объем персональных данных,
                            необходимый только для оказания требуемой услуги или проведения
                            необходимой транзакции.
                        </li> <li>Компания оставляет за собой право вносить изменения в одностороннем
                            порядке в настоящие правила, при условии, что изменения не
                            противоречат действующему законодательству РФ. Изменения условий
                            настоящих правил вступают в силу после их публикации на Сайте.
                        </li></ul>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="contained" color="error" onClick={handleClose}>
                        Закрыть 
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    
    }

    if(pState === 'editing'){
    return(
        <form className="container" style={{padding: "10px"}}>
            <div className="row" >
                <div className="col-12 col-lg-6 grey" style={{paddingBottom: "10px"}}>
                    <h3 style={{marginTop: "20px"}}>Клиент</h3>
                    <div className="client-form">
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Электронная почта</Form.Label>
                            <Form.Control type="email" isInvalid={!validMail} className="form-control" placeholder="metry@group.ru" value={mail} onChange={mailHandler}/>
                            {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">ФИО</Form.Label>
                            <Form.Control type="text" className="form-control" placeholder="Иванов Иван Иванович" value={name} isInvalid={!validName} onChange={nameHandler}/>
                            <Form.Control.Feedback type="invalid">
                                Введите полное имя
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Дата рождения :</Form.Label>
                            <Form.Control type="date" className="form-control" value={bDay} onChange={bdayHandler} isInvalid={!validBDay}/>
                        </Form.Group>

                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Серия и номер паспорта</Form.Label>
                            <Form.Control type="text" className="form-control" placeholder="0000 000000" value={passport} onChange={passportHandler} isInvalid={!validPassport}/>
                            <Form.Control.Feedback type="invalid">
                                Введенные данные должны совпадать с паспортными
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Кем выдан</Form.Label>
                            <Form.Control type="text" className="form-control" placeholder="Отделом УФМС Росии по Тюм.Области в Тюм.Районе" isInvalid={!validWhosGet} value={whosGet} onChange={whosgetHandler}/>
                            <Form.Control.Feedback type="invalid">
                                Введенные данные должны совпадать с паспортными
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Дата выдачи паспорта</Form.Label>
                            <Form.Control isInvalid={!validDateGet} type="date" className="form-control" placeholder="" value={dateGet} onChange={dateGetHandler}/>
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Код подразделения</Form.Label>
                            <Form.Control type="text" className="form-control" placeholder="000000" value={code} onChange={codeHandler} isInvalid={!validCode}/>
                            <Form.Control.Feedback type="invalid">
                                Введенные данные должны совпадать с паспортными
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Адрес регистрации</Form.Label>
                            <Form.Control type="text" className="form-control" placeholder="ул.Республики" value={address} isInvalid={!validAddress} onChange={addressHandler}/>
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Фактический адрес проживания</Form.Label>
                            <Form.Control type="text" className="form-control" placeholder="ул.Республики" value={livingAddress} isInvalid={!validLivingAddress} onChange={livingHandler}/>
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>
                    
                    </div>  
                </div>
                <div className="col-12 col-lg-6 grey">
                    <h3 style={{marginTop: "20px"}}>Объект</h3>
                    <div className="client-form">   
                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Ваш жилой комплекс</Form.Label>
                            <Form.Select isInvalid={!validJK} name="inlineSelect" value={JK} className="form-select form-select-sm app-form-select mb-2" onChange={
                                (e) => {
                                    setJK(e.target.value);
                                    if(e.target.value === "Не выбрано"){
                                        setValidJK(false);
                                    }else{
                                        setValidJK(true);
                                    }
                                }}>
                                <option>Не выбрано</option><option>ЖК Айвазовский</option><option>ЖК Преображенский</option> <option>ЖК Преображенский на Московском</option> <option>ЖК Никольский</option> <option>ЖК Европейский берег</option> <option>ЖК Звездный</option> <option>ЖК Квартал на Московском</option> <option>ЖК Акватория</option> <option>ЖК Олимпия</option> <option>ЖК Москва</option> <option>ЖК Колумб</option> <option>ЖК Горизонт</option> <option>ЖК На Широтной</option> <option>ЖК На Минской</option> <option>ЖК Акварель</option> <option>ЖК Салют</option> <option>ЖК Ария</option> <option>ЖК Орион</option> <option>Вашего ЖК нет в списке</option>
                            </Form.Select>
                        </Form.Group> 

                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Адрес объекта</Form.Label>
                            <Form.Control isInvalid={!validObjectAddress} type="text" value={objectAddress} className="form-control" placeholder="ул.Республики"  onChange={objectHandler}/>
                        </Form.Group>    

                        <Form.Group className="group-style">
                            <Form.Label className="form-Form.Label">Площадь квартиры, без учета лоджии, м² </Form.Label>
                            <Form.Control type="text" value={area} className="form-control" placeholder="45" isInvalid={!validArea}  onChange={areaHandler}/>
                        </Form.Group>

                        <Form.Group className="group-style"> 
                            <Form.Label htmlFor="" className="form-Form.Label">Количество комнат</Form.Label>
                            <Form.Select className="form-select form-select-sm app-form-select mb-2" onChange={
                                (e) => {
                                    if(e.target.value === "Студия"){
                                        setRooms("0");
                                        return;
                                    }
                                    setRooms(e.target.value);
                                    if(e.target.value === "Не выбрано"){
                                        setValidRooms(false);
                                    }else{
                                        setValidRooms(true);
                                    }
                                }
                            } value={rooms} isInvalid={!validRooms}>
                                <option>Не выбрано</option><option value={"0"}>Студия</option> <option>1 комнатная</option> <option>2 комнатная</option> <option>3 комнатная</option> <option>4 комнатная</option> <option>Более...</option>
                            </Form.Select>
                        </Form.Group>   
                        
                        <Form.Group className="group-style">
                            <Form.Label htmlFor="" className="form-Form.Label">Количество санузлов</Form.Label>
                            <Form.Select isInvalid={!validToilets} className="form-select form-select-sm app-form-select mb-2" onChange={
                                (e) => {
                                    setToilets(e.target.value);
                                    if(e.target.value === "Не выбрано"){
                                        setValidToilets(false);
                                    }else{
                                        setValidToilets(true);
                                    }
                                }} value={toilets}>
                                <option>Не выбрано</option><option>1</option> <option>2</option> <option>3</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="row">
            {showAgreeRequired === true ? <p style={{color: "red", marginBottom: "1px"}}>Необходимо согласие</p> : null}
                {props.pdAgree === true ? <div className="checkboxs">   
                    <Checkbox checked={agree} onChange={() => {setAgree(!agree); setPersonalDataCorrect(true)}}/><Form.Label htmlFor="" className="ml-9 mt-2">Я даю согласие на обработку <a href="#" onClick={handleShow}>персональных данных</a></Form.Label>
                </div> : null}
                <div>
                    {personalDataCorrect === false ? <p style={{color: "red", marginBottom: "10px"}}>Проверьте корректность введеных данных</p> : null}
                    <Button variant="contained" color="error" onClick={sendDataHandler} disabled = {!formValid && !agree}>{props.btntxt}</Button>
                </div>
            </div>
            <PdModal/>
        </form>
    )
    }
    if(pState === "error"){
        return(
            <div style={{backgroundColor: 'whitesmoke', padding: "10px"}}>
                <h2>Персональные данны не были изменены</h2>
                <p>Проверьте корректность введенных данных</p>
                <Button variant="contained" color="error" onClick={errorButtonHandler}>Назад</Button>
            </div>
        )
    }   
    if(pState === "done"){
        return(
            <div style={{backgroundColor: 'whitesmoke', padding: "10px"}}>
                <h2>Персональные данны были изменены</h2>
                <p>Если потребуется редактировать данныe, вернитесь в этот раздел</p>
                <Button variant="contained" color="error" onClick={successButtonHandler}>На главную</Button>
            </div>
        )
    }
    return(<></>)

}