import React from 'react'
import AdminTileLine from '../../Components/TilesLine/TileLine'

export default function AdminMain() {
  return (
    <>
      <AdminTileLine/>
    </>
  )
}
