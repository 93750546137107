import React from 'react'
import {useState, useEffect} from "react"
import { FormControl, TextField, Button } from '@mui/material'


export default function AdminEditContactPage() {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [image, setImage] = useState("");
    const [role, setRole] = useState("");

    useEffect( () => {
        const url = `${process.env.REACT_APP_API_URL}index/get_contact`
        const getData = async () => {
          const res = await fetch(url, {
            method: "POST",
            headers: {
              "Authorization" : (localStorage.getItem("token") as string)
            },
            body: JSON.stringify({id: 1})
          })
          res
            .json()
            .then((data) => data.data)
            .then((data: any) => {
                setName(data.full_name);
                setMail(data.mail);
                setImage(data.image);
                setPhone(data.phone);
                setRole(data.role)
            })
        }
        getData();
    
      }, []);

    const submitHandler = async () => {
        const url = `${process.env.REACT_APP_API_URL}index/change_contact`;
        const body = {
            full_name: name,
            role: role,
            phone: phone,
            mail: mail,
            image: image
        }
        const res = fetch(url, {
            method: "POST",
            headers: {
                "Authorization" : (localStorage.getItem("token") as string)
            },  
            body: JSON.stringify(body)
        })



    }   

    return (
        <div className='contact-index'>
            <h2>Изменение контактов руководителя коммерческого отдела</h2>
            <FormControl >
                <TextField label={"ФИО"} margin='normal' onChange={(e) => {setName(e.target.value)}} value={name}/>
                <TextField label={"Почта"} margin='normal' onChange={(e) => {setMail(e.target.value)}} value={mail}/>
                <TextField label={"Телефон"} margin='normal' onChange={(e) => {setPhone(e.target.value)}} value={phone}/>
                <TextField label={"Роль"} margin='normal' onChange={(e) => {setRole(e.target.value)}} value={role}/>
                <TextField label={"Изображение"} margin='normal' helperText="Ссылка на изображение из соц-сети" onChange={(e) => {setImage(e.target.value)}} value={image}/>
                <Button variant='contained' onClick={submitHandler}>Изменить</Button>
            </FormControl>
            
            
        </div>
  )
}
