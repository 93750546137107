import React from 'react'
import {useState, useEffect} from "react"
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import "./profilestyle.css"

const ProfileIndex = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: var(--bs-gray-150);
` 

const ProfileCenter = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    padding-top: 10px;
    width: 700px;
    height: fit-content;
    background-color: #ffffff;
    z-index: 20;
    padding-left: 8px;
    padding-right: 8px;
    
`

const DirectDP = styled(Link)`
    color: black: ;
    text-decoration: underline;
    transition: all 500ms;

    :hover{
        color: #00000050;
    }
`

type fetchedData = {
        avatar: any,
        avatar_min: any,
        date_change: any,
        email: string,
        login: string,
        password: string,
        phone: string,
        user_id: string | number,
        user_name: string,
        user_role: string
    }



export default function UserProfile() {
    
    const [data, setData] = useState<fetchedData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState(null);


    // const [vendor, setVendor] = useState<string>("Необходимо указать");
    const [JK, setJK] = useState<string>("Необходимо заполнить");
    const [address, setAddress] = useState<string>("Необходимо заполнить");

    const [moneyPayed, setMoneyPayed] = useState<string>("Не указано");
    const [moneyTotal, setMoneyTotal] = useState<string>("Не указано");
    const [moneyLeft, setMoneyLeft] = useState<string>("-");
    const [dateStart, setDateStart] = useState<string>("Ремонт еще не начался");
    const [dateEnd, setDateEnd] = useState<string>("Даты согласовываются");
    
    


    useEffect(() => {
        
        const getUserData = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_user`
            var token = localStorage.getItem("token");
            var id = localStorage.getItem("id")  
            if(token ==  null || id == null){
                token = ""
                id = "1"
            }
            
            const resBody = {
                id: id  
            } 
            
            const data = await fetch(url, {
                method : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : token,    
                },
                body: JSON.stringify(resBody)
            })

            data
            .json()
            .then(data => {
                setData(data.data);
                setLoading(false);
                
            })
            .catch(err => setError(err))
        }
        
        const getVendorInfo = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_vendor_info_by_user_id`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "authorization" : localStorage.getItem("token") as string
                },
                body: JSON.stringify(
                    {
                        user_id: localStorage.getItem("id") as string
                    }
                )
            })

            res.json()
                .then(data =>  {
                    if(data.data !== null){
                        setJK(data.data.jk);
                        setAddress(data.data.object_address);
                    }
                }
            )

        } 

        const getContractInfo = async () => {
            const url = `${process.env.REACT_APP_API_URL}index/get_contract_data`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization" : (localStorage.getItem("token") as string)
                },
                body: JSON.stringify({
                    user_id: localStorage.getItem("id") as string
                })
            })
            res
                .json()
                .then(res => {
                    if(res.res === -1){
                        return;
                    }

                    if(res.data.date_start !== null && res.data.date_end !== null){
                        setDateStart(res.data.date_start);
                        setDateEnd(res.data.date_end);
                    }

                    if(res.data.money_total !== null && res.data.money_payed !== null){
                        setMoneyTotal(res.data.money_total);
                        setMoneyPayed(res.data.money_payed);
                        setMoneyLeft(`${(parseInt(res.data.money_total) - parseInt(res.data.money_payed))}`)
                    }
                })
                
        }

        getUserData();
        getVendorInfo();
        getContractInfo();
    }, [])

    if(loading){
        return (
            <ProfileIndex className='privet '>
                
                <ProfileCenter className='hello'>
                    <section className='avatar-and-names'>
                        <div className='avatar'>
                            <Skeleton variant={"circular"} width={90} height={90} />
                        </div>
                        <div className='names'>
                            <Skeleton variant={"rounded"} height={30} style={{marginBottom:"6px"}}/>
                            <Skeleton variant={"rounded"} height={25} style={{marginBottom:"6px"}}/>
                            <Skeleton variant={"rounded"} height={20} style={{marginBottom:"6px"}}/>
                        </div>
                    </section>
                </ProfileCenter>
            </ProfileIndex>
          )
    }
    if(error){
        return(
            <div>Что то пошло не так</div>
        )
    }
    // const dateStart = Date.parse(data?.date_change)
    // const TimeNeed = Date.parse("1970-1-15 00:00:00:0");
    // const finish = new Date(dateStart + TimeNeed);
    
    // const d = new Date(data?.date_change)
    return(
        
        <ProfileIndex className='privet '>
                <ProfileCenter className='hello'>
                    <section className='avatar-and-names'>
                        <div className='avatar'>
                            <Avatar   sx={{ width: 90, height: 90}}/>
                        </div>
                        <div className='names'>
                            <h3>{data?.user_name}</h3>
                            <h5>{data?.email}</h5>
                            <hr />
                            {/* <h5>Застройщик - {vendor}</h5> */}
                            {JK === "Необходимо заполнить" ? <h5>Жилой комплекс - <DirectDP to={"/contact"} style={{color: "black"}}>{JK}</DirectDP></h5> : <h5>Жилой комплекс - {JK}</h5>}
                            {address === "Необходимо заполнить" ? <h5>Адрес объекта - <DirectDP to={"/contact"} style={{color: "black"}}>{address}</DirectDP></h5> : <h5>Адрес объекта - {address}</h5>}
                            <hr />
                            <h5>Сумма договора - {moneyTotal == "Не указано" ? moneyTotal : moneyTotal + "₽"}</h5>
                            <h5>Оплачено - {moneyPayed == "Не указано" ? moneyPayed : moneyPayed + "₽"}</h5>
                            <h5>Остаток - {moneyLeft}₽</h5>
                            <hr />
                            <h6 style={{marginBottom: "2px"}}>Начало ремонта: {dateStart.split("-")[2]}-{dateStart.split("-")[1]}-{dateStart.split("-")[0]}</h6>
                            <h6>Окончание ремонта: {dateEnd.split("-")[2]}-{dateEnd.split("-")[1]}-{dateEnd.split("-")[0]}</h6>
                        </div>
                    </section>
                </ProfileCenter>
            </ProfileIndex>
    )

}
