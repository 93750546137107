import React from "react"
import styled from "styled-components"

const logo = require("../../static/images/logo.png");

const Footerr = styled.footer`
    background-color: var(--bs-gray-600);
    height: fit-content;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
`

const FooterWrap = styled.div`
    @media all and (max-width: 500px){
        width: 100%;
    }

    width: 80%;
    max-width: 1400px;
    height: fit-content;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .logo-section{
        @media screen and (max-width: 300px){
            margin-left: 50px;
            width: 100px;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;    
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .data-section{
        width: 1000px;
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .col{
        @media screen and (max-width: 400px){
            width: 250px;
        }
        @media screen and (max-width: 300px){
            width: 150px;
        }
        width: fit-content;
        height: fit-content;
        display: flex;
    }

    .col-1{
        justify-content: flex-start;
    }

`

const FooterList = styled.ul`
    color: white;
    text-decoration: none ;
    list-style-type: none;
    margin: 20px;
    
    a{
        color: white;
        text-decoration: none;
    }
` 

type left_col = {
    link: string,
    text: string
}

const left_col_data: Array<left_col> = [
    {
        link: "https://metrygroup.ru/portfolio",
        text: "Портфолио"
    },
    {
        link: "https://metrygroup.ru/contact",
        text: "Контакты"    
    },
    // {
    //     link: "https://metrygroup.ru/vakansii",
    //     text: "Вакансии"
    // },
    // {
    //     link: "https://metrygroup.ru/#",
    //     text: "Сотрудничество"
    // }
]

const footer_right_col: Array<string> = [
    "ООО «Метры»",
    "Г. Тюмень, Ул. Мельникайте Д.123а, Офис 305",
    "Тел: +7 (3452) 56-24-25",
    "Email: Info@Metrygroup.Ru",
    "Метры Group © 2021",
    'Разработан ООО Уралмикро'
]

export default function Footer(props: any)
{
    return(
        <Footerr>
            <FooterWrap>
            <div className="col col-0 logo-section">
                <img src={logo} alt="here should be our logo" width={"150px"}/>
            </div>
            <div className="data-section">
                <div className="col col-1">
                    <FooterList className="footer-list">
                        {left_col_data.map((item: left_col, index: number) => {
                            return(
                                <a href={item.link} key={index}><li>{item.text}</li></a>
                            )
                        })}
                        
                    </FooterList>
                </div>
                <div className="col col-2">
                    <FooterList className="footer-list">
                        
                        {footer_right_col.map((text: string, index: number) => {
                            if(text === "Тел: +7 (3452) 56-24-25"){
                                return(
                                    <li key={index}>Тел: 
                                        <a href="tel: +7 (3452) 56-24-25">+7 (3452) 56-24-25</a>
                                    </li>
                                )
                            }
                            return(
                                <li key={index}>{text}</li>
                            )
                        })}
                        
                    </FooterList>
                </div>
            </div>
            </FooterWrap>
        </Footerr>
    )

}