import React, {useEffect} from "react"

import styled from "styled-components"
import AuthForm from "../../Components/AuthForm/AuthForm"

const AuthIndex = styled.div`
    @media only screen and (max-width: 800px){
        
        overflow-y: hidden;
    }
    overflow: hidden;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
    background: linear-gradient(150deg, #a73232, #1a0000);
    display: flex;
    justify-content: center;
    align-items: center;
`



export default function AuthPage(props: any){

    useEffect(() => {
        localStorage.clear();
    })

    return(
        <AuthIndex>
            <AuthForm/>
        </AuthIndex>
    )

}
